export const CALENTAR_LABELS ={
    labelPrevDecade: 'Década anterior',
    labelPrevYear: 'Año anterior',
    labelPrevMonth: 'Mes pasado',
    labelCurrentMonth: 'Este mes',
    labelNextMonth: 'Próximo mes',
    labelNextYear: 'Próximo año',
    labelNextDecade: 'Próxima década',
    labelToday: 'Hoy',
    labelSelected: '',
    labelNoDateSelected: 'No ha seleccionado una fecha',
    labelCalendar: 'Calendario',
    labelNav: 'Calendario',
    labelHelp: 'Use el cursor para seleccionar las fechas'
  }