
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class TermsPopup extends Vue {
    @Prop() showModal!: boolean;
    @Prop() title!: string;
    @Prop() text!: string;
    @Prop() action?: boolean;
    show: boolean = false;

/* MODAL */
    @Watch('showModal')
     renderModal(){
        this.show = this.showModal;
    }

    @Watch('show')
    sendToParent(newVal: boolean){
        this.$emit('changeModalStatus', newVal)
    }
/* END MODAL */

    sendAction(){
        this.$emit('sendAction')
    }

}

