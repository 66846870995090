
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
/* STORE */
import {banks} from '@/store/namespaces';
import BanksMethods from '@/store/banks/methods/banks.methods'
/* CONSTANTS */
/* INTERFACES */
import {BankAccount} from '@/modules/banks/interfaces/bankAccounts.interface'
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue'
import InputFile from '@/components/utilities/InputFile.vue'
import Loader from '@/components/utilities/Loader.vue';
/* FUNCTIONS */


@Component({
    components: {
        InputFile,
        SvgIcon,
        Loader
    },
})
export default class AccountList extends Vue {

    $refs : any = {}

    async mounted(){
        try{
            this.$refs.loader.showLoader();
            await this.fetchBankAccount({countryId: 2,currencyId:2});
        }catch(e){
           this.$router.push({name:'Dashboard'}).catch();
        }
        finally{
            this.$refs.loader.hideLoader();
        }
    }

    selectBank(bank: BankAccount){
        this.bankAccounts.forEach(el=>{
            el.check = false
        })
        bank.check = !bank.check
    }

    @banks.Action(BanksMethods.actions.FETCH_BANK_ACCOUNTS)
    fetchBankAccount!:(data:{countryId: number,currencyId: number}) => Promise<boolean>
    @banks.Getter(BanksMethods.getters.GET_BANK_ACCOUNTS)
    bankAccounts!: BankAccount[];
}
