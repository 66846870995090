
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
/* VUELIDATE */
import { validationMixin } from 'vuelidate';
import { email, required,requiredIf} from 'vuelidate/lib/validators';
/* STORE */
import {auth, banks, beneficiaries,countries, docTypes, payMethods, remittance} from '@/store/namespaces'
import BeneficiariesMethods from '@/store/beneficiaries/methods/beneficiaries.methods'
import CountriesMethods from '@/store/countries/methods/countries.methods'
import PayMethodsMethods from '@/store/payMethods/methods/payMethods.methods'
import DocTypesMethods from '@/store/docTypes/methods/docTypes.methods'
import AuthMethods from '@/store/auth/methods/auth.methods'
import BanksMethods from '@/store/banks/methods/banks.methods'
import RemittanceMethods from '@/store/remittance/methods/remittance.methods'
/* INTERFACES */
import { FrequentBeneficiary } from '@/modules/beneficiaries/interfaces/beneficiaries.interfaces';
import { Country, NationalityCountry } from '@/modules/countries/interfaces/countries.interface';
import { PayMethod } from '@/modules/payMethods/interfaces/payMethods.interface';
import { CountryDocType } from '@/modules/docTypes/interfaces/docType.interface';
import { Beneficiary, NewRemittance } from '../../interfaces/newRemittance.interface';
/* COMPONENTS */
import Header from '@/components/layout/Header.vue';
import InfoSide from '@/components/InfoSide.vue';
import Stepper from '@/components/utilities/Stepper.vue';
import FirstStep from '@/modules/verificationLevels/components/levelOne/FirstStep.vue'
import SecondStep from '@/modules/verificationLevels/components/levelOne/SecondStep.vue'
import ThirdStep from '@/modules/verificationLevels/components/levelOne/ThirdStep.vue'
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import Calculator from '@/modules/remittance/components/Calculator.vue';
import ConfirmationModal from '@/components/utilities/ConfirmationModal.vue';
import InfoModal from '@/components/utilities/InfoModal.vue';
import Loader from '@/components/utilities/Loader.vue';
import CreateFrequentBeneficiary from '@/modules/beneficiaries/components/createBeneficiary/CreateFrequentBeneficiary.vue';
/* FUNCTIONS */
import { showToast } from '@/utils/global-functions';
import { User } from '@/modules/auth/interfaces/user.interface';
/* CONSTANTS */
import {FIELDS} from '@/modules/payMethods/constants/fields.constant'
import { Bank } from '@/modules/banks/interfaces/bank.interface';
import { ACCOUNT_TYPES_ARRAY } from '@/modules/accounts/contants/account.constants';
import { NotificationType } from '../../interfaces/notificationTypes.interface.interface';
import {NOTIFICATION_TYPES_LABELS, NOTIFICATION_VALUES} from '@/modules/remittance/constants/notification.constants';
import { REMITTANCE_TYPES } from '../../constants/remittance.constants';
/* DIRECTIVES */
import {VMoney} from 'v-money'
/* LIBRARIES */
import VuePhoneNumberInput from 'vue-phone-number-input';
@Component({
    components: {
        Header,
        Loader,
        InfoSide,
        Stepper,
        FirstStep,
        SecondStep,
        ThirdStep,
        SvgIcon,
        Calculator,
        ConfirmationModal,
        InfoModal,
        CreateFrequentBeneficiary,
        VuePhoneNumberInput
    },
    mixins: [validationMixin],
    validations:{
        newRemittance:{
            beneficiariesInfo:{
                beneficiaries:{
                    $each: {
                        notification:{
                            phoneNumber:{
                                value:{ required: requiredIf(function(value){
                                 if (value.required === true)
                                    return true
                                })},
                                unformattedValue:{ required: requiredIf(function(value){
                                 if (value.required === true)
                                    return true
                                })}
                            },
                            email:{value:{email,required: requiredIf(function(value){
                                 if (value.required === true) return true})}}
                        }
                    }
                }
            }
        }
    },
    directives:{
        money: VMoney
    }
})
export default class Beneficiaries extends Vue {
    // @Prop() remittance!: any;
    addBeneficiarySectionActive: boolean = false;
    notificationTypesLabels = NOTIFICATION_TYPES_LABELS;
    notificationValues  = NOTIFICATION_VALUES;
    check = false;
    remittanceTypes = REMITTANCE_TYPES;
    infoModal= {
        title: 'Al continuar, contará con 15 minutos para anexar su comprobante de pago.',
        open: false
    }
    mainTranslations: any = {
        countrySelectorLabel: 'Código país',
        countrySelectorError: 'Elige un país',
        phoneNumberLabel: '',
        example: 'Ejemplo :',
    };
    createBeneficiaryModal = {
        open: false,
        beneficiary: null,
        tabIndex: 0,
        type: 'addToList',
        title: 'Agregar Beneficiario',
        extraData:{
            idCountry: '',
            idCurrency:0,
            nameCountry:'',
            currencyCode: '',
            countryCode: ''
        },
        simulated: false
    };
    formattedCurrency={
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
          precision: 2,
          masked: true 
    }
    formattedDestinyCurrency={
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
          precision: 2,
          masked: true 
    }
    fields = FIELDS;
    accountTypes=ACCOUNT_TYPES_ARRAY;
    showNotificationModal: boolean = false;
    countryDocTypes = []
    // payMethods : PayMethod[] = [];
    banks: Bank[] = []
    beneficiaries = []
    selectedBeneficiaryIndex = null;
    deleteConfirmationModal = false;
    currentBeneficiary = {
        id_country_doc_type: null,
        id_pay_method: null,
        type_doc_type: null,
        ident_doc_number: null,
        id_bank: null,
        account_number: null,
        account_type: null
    }
    distributeByOriginCurrency = true;
    distributeByDestinyCurrency = false;
    $refs: any={}
    currentPhonePayLoad =  null
    loader = false;
    writingInOriginValGlobal = null;
//currentBeneficiaries: FrequentBeneficiary[] = []

    async mounted(){
        try{
            this.$emit('loaderOn')
            this.formattedCurrency.suffix= ' '+this.newRemittance.countryCurrency.isoCode;
            this.formattedDestinyCurrency.suffix = ' '+this.newRemittance.beneficiariesInfo.destinationCurrency.isoCode
            if (this.filteredBeneficiaries.length <= 0) this.addBeneficiarySectionActive = true;
            await this.fetchNotificationTypes();
            this.createBeneficiaryModal.extraData ={
                idCountry: this.newRemittance.beneficiariesInfo.destinationCountry.idCountry,
                idCurrency: this.newRemittance.beneficiariesInfo.destinationCurrency.idCourrency,
                nameCountry: this.newRemittance.beneficiariesInfo.destinationCountry.name,
                currencyCode: this.newRemittance.beneficiariesInfo.destinationCurrency.isoCode,
                countryCode: this.newRemittance.beneficiariesInfo.destinationCountry.code,
            }
            await this.fetchAllCountries();
            this.$emit('loaderOff')
        }catch(e){
            this.$router.push({name: 'ErrorPage'}).catch();
        }
    }

    /* MODALS */
    deleteConfirmationModalStatus(newVal: boolean){
        this.deleteConfirmationModal = newVal;
    }
    createBeneficiaryModalStatus(newVal: boolean) {
        this.createBeneficiaryModal.open = newVal;
    }
    informationModalStatus(newVal: boolean){
        this.infoModal.open = newVal;
    }
    /* END MODALS */

    get filteredBeneficiaries(){
        return this.frequentBeneficiaries.map(el=> {if (el.country_code === this.newRemittance.beneficiariesInfo.destinationCountry.code) return el})
    }

    get totalDistributed(){
        return parseFloat(this.newRemittance.beneficiariesInfo.beneficiaries.map(item => item.ammount.value).reduce((prev, curr) => prev + curr, 0).toFixed(2))
    }

    get totalDistributedInOriginCurrency(){
        return parseFloat(this.newRemittance.beneficiariesInfo.beneficiaries.map(item => item.originAmmount.value).reduce((prev, curr) => prev + curr, 0).toFixed(2))
    }

    get totalDistributedFormatted(){
        return this.$options.filters.currency(this.totalDistributed)
    }
    
    get totalDistributedInOriginCurrencyFormatted(){
        return this.$options.filters.currency(this.totalDistributedInOriginCurrency)
    }

    get toAssignFormatted(){
        return this.$options.filters.currency(this.newRemittance.totalDestinationRemittance-this.totalDistributed) +' '+ this.newRemittance.beneficiariesInfo.destinationCurrency.isoCode
    }
    get toAssign(){
        return this.newRemittance.totalDestinationRemittance-this.totalDistributed;
    }

    get toAssignInOriginCurrencyFormatted(){
            return this.$options.filters.currency(this.newRemittance.totalOriginRemittance-this.totalDistributedInOriginCurrency)+ ' '+ this.newRemittance.countryCurrency.isoCode
    }

    get toAssignInOriginCurrency(){
        return this.newRemittance.totalOriginRemittance-this.totalDistributedInOriginCurrency;
    }

    get someEmptyBeneficiary(){
        return this.newRemittance.beneficiariesInfo.beneficiaries.some(el=> el.ammount.value <=0)
    }

    get countryName(){
        return this.destinyCountries.find(el => el.country_iso_code === this.newRemittance.beneficiariesInfo.destinationCountry.code).name_country
    }

    // get selectedBen(){
    //     return this.currentBeneficiaries.filter(el => {if (el.check === true) return el})
    // }

     get totalRemittanceCurrency(){
        return this.$options.filters.currency(this.newRemittance.totalOriginRemittance)+ ' '+ this.newRemittance.countryCurrency.isoCode
    }

    get totalDestinyRemittanceCurrency(){
        return this.$options.filters.currency(this.newRemittance.totalDestinationRemittance)+' '+ this.newRemittance.beneficiariesInfo.destinationCurrency.isoCode
    }



     deleteBen(beneficiaryIndex: number){
        this.selectedBeneficiaryIndex = beneficiaryIndex;

        this.deleteConfirmationModal = true;
    }

    async deleteConfirmed(){
        try{
            let remittanceBen = this.newRemittance.beneficiariesInfo.beneficiaries[this.selectedBeneficiaryIndex]
            if (remittanceBen && remittanceBen.frequent){
                let frequentBeneficiary = this.frequentBeneficiaries.find(el => el.nickname === remittanceBen.alias)
                if (frequentBeneficiary) frequentBeneficiary.check = false
                let simulatedBeneficiary = this.simulatedFrequentBeneficiaries.find(el => el.nickname === remittanceBen.alias)
                if (simulatedBeneficiary) simulatedBeneficiary.check = false
                this.newRemittance.beneficiariesInfo.beneficiaries.splice(this.selectedBeneficiaryIndex,1);
           }
            showToast('Beneficiario eliminado exitosamente','success');
        }catch(e){
            showToast('Ha ocurrido un error eliminado al beneficiairo','error');
        }
        finally{
            this.deleteConfirmationModal = false;
        }
    }

    updateMainPhone(payload: any) {
        this.currentPhonePayLoad = payload;
    }

    addBeneficiary(newVal: {beneficiary:FrequentBeneficiary,frequent: boolean,add?: boolean}){
            let toAdd : Beneficiary  = {
                name:{value: newVal.beneficiary.owner_name,pending: false},
                bank: {value: {idBank: newVal.beneficiary.id_bank,name: newVal.beneficiary.name_bank},pending: false},
                paymentType: {value: {idPayMethod: newVal.beneficiary.id_pay_method,name: newVal.beneficiary.name_pay_method,fields: []},pending: false},
                frequent: {value: newVal.frequent,pending: false},
                idNumber: {value: newVal.beneficiary.identification,pending: false},
                account: {value: newVal.beneficiary.account,pending: false},
                accountType: {value: newVal.beneficiary.account_type,pending: false},
                ammount:{value:0,pending: false},
                originAmmount:{value:0,pending:false},
                phoneNumber: {value: newVal.beneficiary.phone_number,pending: false},
                email: {value: newVal.beneficiary.email,pending: false},
                // notification:{idNotificationType: null,name:{value: null, pending: false},fields:[],phoneNumber:{value: null, unformattedValue: null,pending: false,required:false},email:{value: null, pending: false,required:false}},
                alias: newVal.beneficiary.nickname,
                docType: {value:{id_ident_doc_type:newVal.beneficiary.id_doc_type,name_doc_type:newVal.beneficiary.name_doc_type},pending: false},
                // relationship:{value: newVal.beneficiary.relationship,pending:false},
                relation_type: newVal.beneficiary.relation_type,
                pending: false,
                id_frequent_beneficiary: newVal.frequent ? parseInt(newVal.beneficiary.id_beneficiary) : null,
                id_optional_field: newVal.beneficiary.id_optional_field,
                name_optional_field: newVal.beneficiary.name_optional_field,
                notification: newVal.beneficiary.notification
            }

            if (newVal.add){
                this.frequentBeneficiaries.push(newVal.beneficiary)
            }
            if (this.newRemittance.beneficiariesInfo.beneficiaries.length < this.maxBeneficiariesPerRemittance){
                this.newRemittance.beneficiariesInfo.beneficiaries.push(toAdd)
            }
            else {
                newVal.beneficiary.check = false;
                showToast(`Puede seleccionar hasta ${this.maxBeneficiariesPerRemittance} beneficiarios`,'error')
            }
    }

    addFrequentBeneficiaries(beneficiaries: FrequentBeneficiary[]){
        this.newRemittance.beneficiariesInfo.beneficiaries = this.newRemittance.beneficiariesInfo.beneficiaries.filter(el=> el.frequent.value !== true)
        beneficiaries.forEach(el =>{
            this.addBeneficiary({beneficiary: el, frequent:true})
        })
    }

    refreshBeneficiaries(){

    }

    get isTotalDistributed(){
        if ((this.newRemittance.beneficiariesInfo.originDistribution && (this.totalDistributedInOriginCurrency !== this.newRemittance.totalOriginRemittance || this.someEmptyBeneficiary))
            ||
            (!this.newRemittance.beneficiariesInfo.originDistribution && (this.totalDistributed !== this.newRemittance.totalDestinationRemittance || this.someEmptyBeneficiary))
            )
            return false
        else return true
    }

    get badgeName(){
        if ((this.newRemittance.beneficiariesInfo.originDistribution && (this.totalDistributedInOriginCurrency !== this.newRemittance.totalOriginRemittance || this.someEmptyBeneficiary))
            ||
            (!this.newRemittance.beneficiariesInfo.originDistribution && (this.totalDistributed !== this.newRemittance.totalDestinationRemittance || this.someEmptyBeneficiary))
            )
            return 'Debe distribuir el monto total entre los beneficiarios'
        else return 'Monto total correctamente distribuido'
    }

    get badgeColor(){
       if ((this.newRemittance.beneficiariesInfo.originDistribution && (this.totalDistributedInOriginCurrency !== this.newRemittance.totalOriginRemittance || this.someEmptyBeneficiary))
            ||
            (!this.newRemittance.beneficiariesInfo.originDistribution && (this.totalDistributed !== this.newRemittance.totalDestinationRemittance || this.someEmptyBeneficiary))
            )
            return 'badge-rejected'
        else return 'badge-sent'
    }

    get badgeIcon(){
      if ((this.newRemittance.beneficiariesInfo.originDistribution && (this.totalDistributedInOriginCurrency !== this.newRemittance.totalOriginRemittance || this.someEmptyBeneficiary))
            ||
            (!this.newRemittance.beneficiariesInfo.originDistribution && (this.totalDistributed !== this.newRemittance.totalDestinationRemittance || this.someEmptyBeneficiary))
            )
            return 'x-circle'
        else return 'shield-fill-check'
    }

    get defaultCountryCodeNotification(){
        return this.newRemittance.beneficiariesInfo.destinationCountry.code
    }

     addNotifications(){
        this.$v.$touch();
        if (!this.$v.$invalid){
            this.showNotificationModal = false;
            if (this.$route.params.modo === REMITTANCE_TYPES.WITH_CAPTURE)  this.$emit('nextStep')
            else this.infoModal.open = true;
           //
        }    
    }

    addTotalAvailable(beneficiary: Beneficiary,index: number){
        if (this.newRemittance.beneficiariesInfo.originDistribution){
            this.writingInOriginValGlobal = true;
            if (this.toAssignInOriginCurrency > 0) beneficiary.originAmmount.value = this.toAssignInOriginCurrency;
            else if (this.toAssignInOriginCurrency < 0) beneficiary.originAmmount.value = beneficiary.originAmmount.value  + this.toAssignInOriginCurrency;
        }
        else{ 
            this.writingInOriginValGlobal = false;
            if (this.toAssign > 0) beneficiary.ammount.value = this.toAssign;
            else if (this.toAssign < 0) beneficiary.ammount.value = beneficiary.ammount.value  + this.toAssign;
            this.$refs.calculator[index].updateDestinyAmountParent(beneficiary.ammount.value)
        }
    }

    cleanAmounts(){
        this.newRemittance.beneficiariesInfo.beneficiaries.forEach(el=>{
            el.ammount.value = 0;
            el.originAmmount.value = 0;
        })
    }

    get withCapture() : boolean{
        if (this.$route.params.modo === REMITTANCE_TYPES.WITH_CAPTURE) return true
        return false
    }

    async confirmPreRemittanceSave(){
        console.log('1 confirmPreRemittanceSave')
        this.$refs.loader.showLoader();
        if (await this.savePreRemittance(this.newRemittance) && await this.getPreRemittance(this.userData.email_user)){
            console.log('2 confirmPreRemittanceSave')
            this.$emit('nextStep')
        }
        else showToast('Ha ocurrido un error guardando la operación','error')
        this.$refs.loader.hideLoader();
    }

    async nextStep() {
       // this.setBeneficiariesInRemittance();
       console.log('1 nextStep')
        if (this.newRemittance.beneficiariesInfo.beneficiaries.length>0){
            // if (this.isTotalDistributed) this.showNotificationModal = true;
            if (this.isTotalDistributed)
                if (this.$route.params.modo === REMITTANCE_TYPES.WITH_CAPTURE || this.newRemittance.depositMethod.name === 'Webpay') await this.confirmPreRemittanceSave();
                else this.infoModal.open = true;
        } else {
            showToast('Debe seleccionar al menos un beneficiario','error')
        }
    }

    @auth.Getter(AuthMethods.getters.GET_USER_DATA)
    userData! : User;  
    /* BENEFICIARIES */
    @beneficiaries.Getter(BeneficiariesMethods.getters.GET_FREQUENT_BENEFICIARIES)
    frequentBeneficiaries!: FrequentBeneficiary[];
    @beneficiaries.Action(BeneficiariesMethods.actions.DELETE_FREQUENT_BENEFICIARY)
    deleteBeneficiary!: (beneficiaryId: string) => Promise<{msg: string, error: boolean}>
    @beneficiaries.Getter(BeneficiariesMethods.getters.GET_SIMULATED_FREQUENT_BENEFICIARIES)
    simulatedFrequentBeneficiaries!: FrequentBeneficiary[];
    /* COUNTRIES */
    @countries.Getter(CountriesMethods.getters.GET_DESTINY_COUNTRIES)
    destinyCountries!: Country[];
    @countries.Action(CountriesMethods.actions.FETCH_ALL_COUNTRIES)
    fetchAllCountries!: () => Promise<boolean>;
    @countries.Getter(CountriesMethods.getters.GET_ALL_COUNTRIES)
    nationalityCountries!: NationalityCountry[];
    @countries.Action(CountriesMethods.actions.FETCH_COUNTRY_ID_BY_NAME)
    fetchCountryIdByName!:(countryName: string) => Promise<any>
    /* PAY METHODS */
    @payMethods.Action(PayMethodsMethods.actions.FETCH_PAY_METHODS_BY_COUNTRY)
    fetchPayMethods!: (countryId: string) => Promise<PayMethod[] | undefined>
    @payMethods.Action(PayMethodsMethods.actions.FETCH_PAY_METHOD_BY_ID)
    fetchPayMethodById!: (payMethodId: number) => Promise<PayMethod | undefined>
    /* DOC TYPES */
    @docTypes.Action(DocTypesMethods.actions.FETCH_DOC_TYPES)
    fetchDocTypes!: () => Promise<boolean>;
    @docTypes.Getter(DocTypesMethods.getters.GET_DOC_TYPES)
    docTypes!: CountryDocType[];
    /* BANKS */
    @banks.Action(BanksMethods.actions.FETCH_BANKS)
    fetchBanks!: (params: any) => Promise<Bank[] | undefined>
    /* REMITTANCE */
    @remittance.Getter(RemittanceMethods.getters.GET_NEW_REMITTANCE)
    newRemittance!: NewRemittance;
    @remittance.Getter(RemittanceMethods.getters.GET_CURRENT_BENEFICIARIES)
    selectedBeneficiaries!: FrequentBeneficiary[];
    @remittance.Action(RemittanceMethods.actions.SAVE_CURRENT_BENEFICIARIES)
    selectBeneficiary!:(beneficiaries: FrequentBeneficiary[]) => Promise<void>
    @remittance.Action(RemittanceMethods.actions.FETCH_NOTIFICATION_TYPES)
    fetchNotificationTypes!: () => Promise<boolean>
    @remittance.Getter(RemittanceMethods.getters.GET_NOTIFICATION_TYPES)
    notificationTypes!: NotificationType[];
    @remittance.Getter(RemittanceMethods.getters.GET_MAX_BENEFICIARIES_PER_REMITTANCE)
    maxBeneficiariesPerRemittance!: number;
    @remittance.Action(RemittanceMethods.actions.SAVE_PRE_REMITTANCE)
    savePreRemittance!: (preRemittance: NewRemittance)=> Promise<boolean>
    @remittance.Action(RemittanceMethods.actions.GET_PRE_REMITTANCE)
    getPreRemittance!: (emailUser: string)=> Promise<boolean>
}
