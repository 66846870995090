export const ACCOUNT_TYPES = {
    AHORRO: 'Ahorro',
    CORRIENTE: 'Corriente',
    OTHER: 'Otro/Indiferente'
}

export const CHILEAN_ACCOUNT_TYPES = {
    AHORRO: 'Ahorro',
    CORRIENTE: 'Corriente',
    VISTA: 'Vista',
    RUT: 'RUT'
}

export const ACCOUNT_TYPES_ARRAY = Object.values(ACCOUNT_TYPES);
export const CHILEAN_ACCOUNT_TYPES_ARRAY = Object.values(CHILEAN_ACCOUNT_TYPES);