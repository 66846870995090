
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {beneficiaries, auth, payMethods,docTypes, countries, remittance} from '@/store/namespaces'
import BeneficiariesMethods from '@/store/beneficiaries/methods/beneficiaries.methods';
import AuthMethods from '@/store/auth/methods/auth.methods';
import PayMethodsMethods from '@/store/payMethods/methods/payMethods.methods';
import DocTypesMethods from '@/store/docTypes/methods/docTypes.methods'
import { FrequentBeneficiary } from '@/modules/beneficiaries/interfaces/beneficiaries.interfaces';
import {FREQUENT_BENEFICIARY_EMPTY_STATE} from '@/modules/beneficiaries/emptyStates/frequentBeneficiary.emptyState';
/* COMPONENTS */
import Stepper from '@/components/utilities/Stepper.vue';
import FirstStep from '@/modules/beneficiaries/components/createBeneficiary/FirstStep.vue';
import SecondStep from '@/modules/beneficiaries/components/createBeneficiary/SecondStep.vue';
import ThirdStep from '@/modules/beneficiaries/components/createBeneficiary/ThirdStep.vue';
import FourthStep from '@/modules/beneficiaries/components/createBeneficiary/FourthStep.vue';
import {CountryDocType} from '@/modules/docTypes/interfaces/docType.interface';
import CountriesMethods from '@/store/countries/methods/countries.methods';
import { Country,CountryCurrency,NationalityCountry } from '@/modules/countries/interfaces/countries.interface';
import { Currency } from '@/modules/currencies/interfaces/currencies.interface';
import { PayMethod } from '@/modules/payMethods/interfaces/payMethods.interface';
import { Bank } from '@/modules/banks/interfaces/bank.interface'
import { showToast } from '@/utils/global-functions';
import { User } from '@/modules/auth/interfaces/user.interface';
import Loader from '@/components/utilities/Loader.vue';
import SvgIcon  from '@/components/utilities/SvgIcon.vue';
import { NewRemittance } from '@/modules/remittance/interfaces/newRemittance.interface';
import RemittanceMethods from '@/store/remittance/methods/remittance.methods'

@Component({
    components:{
        Stepper,
        FirstStep,
        SecondStep,
        ThirdStep,
        FourthStep,
        Loader,
        SvgIcon
    }
})
export default class CreateFrequentBeneficiary extends Vue {
    @Prop() showModal!: {type: string, open: boolean,beneficiary: FrequentBeneficiary,title: string,extraData?:any,simulated?: boolean, tabIndex?: number};
    show: boolean = false;
    newBeneficiary : FrequentBeneficiary = FREQUENT_BENEFICIARY_EMPTY_STATE;
    extraData : {selectedCountryCurrency:CountryCurrency,selectedPayMethod:PayMethod,selectedBank:Bank,selectedDocCountry: NationalityCountry,selectedDocType:CountryDocType,frequent?: boolean,selectedVenezuelanCIType?: string} = {
        selectedCountryCurrency: {iso_cod: null,id_country: null,id_currency: null,name_country: null,country_iso_code: null,viewing_name: null,},
        // selectedCurrency: {idCourrency: -1, name: '', isoCode:'',type: ''},
        selectedPayMethod: {idPayMethod: null, name: null, fields:[]},
        selectedBank: {idBank:-1,name:''},
        selectedDocCountry:{id_all_country:'',country_iso_code: '',is_latin:false},
        selectedDocType:{id_ident_doc_type:'', acronym:'', name_doc_type:'', id_resid_country:-1,type_doc_type:'', id_ip_country:-1, name_country:''},
        selectedVenezuelanCIType: null,
        frequent: false
    }
    $refs : any ={}
    steps= [
        { component: 'FirstStep', label: '' },
        { component: 'SecondStep', label: '' },
        { component: 'ThirdStep', label: '' },
        { component: 'FourthStep', label: '' },
    ]
    filteredBeneficiaries: FrequentBeneficiary[] = []
    currentComponent = this.steps[0].component
    currentStep = 1;
    nextStepEnabled = false;
    payMethods: PayMethod[]=[]
    tabIndex: number = 0;
    loader : boolean = true;

    async mounted(){
        await this.fetchAllCountries();
        await this.fetchCountriesCurrencies();
    }

    /* MODAL */
        @Watch('showModal.open')
        async renderModal(){
            this.show = await this.showModal.open;
            if (this.showModal.tabIndex >=0) 
            this.tabIndex = this.showModal.tabIndex;
            if (this.show){
                this.steps= [
                        { component: 'FirstStep', label: '' },
                        { component: 'SecondStep', label: '' },
                        { component: 'ThirdStep', label: '' },
                        { component: 'FourthStep', label: '' },
                ]
                this.loader = true;
                // this.$refs.component.showLoader();
                if (this.showModal.type === 'create') this.resetFields()
                else if (this.showModal.type === 'edit'){ 
                    this.resetFields();
                    this.newBeneficiary = Object.assign({},this.showModal.beneficiary);
                    let payMethod =await this.fetchPayMethodById(this.newBeneficiary.id_pay_method)
                    this.payMethods = await this.fetchPayMethodsByCountry({id_country: payMethod.idCountry, id_currency: payMethod.idCurrency,only_pay: true})
                    let selectedDocType = await this.fetchDocTypeById(this.newBeneficiary.id_doc_type)
                    if (payMethod === undefined || this.payMethods === undefined || selectedDocType === undefined){
                        this.error();
                    }
                    else {
                        payMethod.fields = this.payMethods.find(el=> el.idPayMethod === payMethod.idPayMethod).fields;
                        if (payMethod.fields.length === 0){
                            this.steps.pop();
                        }
                        this.extraData.selectedCountryCurrency.id_country = payMethod.idCountry;
                        this.extraData.selectedCountryCurrency.viewing_name = payMethod.nameCountry;
                        this.extraData.selectedCountryCurrency.id_currency = payMethod.idCurrency;
                        this.extraData.selectedCountryCurrency.iso_cod = payMethod.nameCurrency;
                        this.extraData.selectedPayMethod = payMethod;
                        this.extraData.selectedDocType = selectedDocType;
                        this.extraData.selectedDocCountry.id_all_country = selectedDocType.id_ip_country.toString();
                        this.extraData.selectedDocCountry.country_name = selectedDocType.name_country;
                        this.extraData.selectedBank.idBank = this.newBeneficiary.id_bank;
                        this.extraData.selectedBank.name = this.newBeneficiary.name_bank;
                    }
                }else{
                    this.resetFields();
                    this.extraData.selectedCountryCurrency.id_country = await this.showModal.extraData.idCountry;
                    this.extraData.selectedCountryCurrency.viewing_name = await this.showModal.extraData.nameCountry;
                    this.extraData.selectedCountryCurrency.id_currency = await this.showModal.extraData.idCurrency;
                    this.extraData.selectedCountryCurrency.iso_cod = await this.showModal.extraData.currencyCode;
                    this.extraData.selectedCountryCurrency.country_iso_code = await this.showModal.extraData.countryCode;
                    this.payMethods = await this.fetchPayMethodsByCountry({id_country: this.extraData.selectedCountryCurrency.id_country, id_currency: this.extraData.selectedCountryCurrency.id_currency })
                    this.filteredBeneficiaries = []
                    if (this.showModal.simulated)
                        this.filteredBeneficiaries = this.simulatedFrequentBeneficiaries.map(el=> el)
                    else{ 
                        //@ts-ignore
                        this.filteredBeneficiaries = this.frequentBeneficiaries.filter(el=> {if (parseInt(el.id_country) === parseInt(this.extraData.selectedCountryCurrency.id_country)) return el })
                    }
                }
                this.loader = false;
                // this.$refs.component.hideLoader();
                this.currentStep = 1;
                this.currentComponent = this.steps[0].component
            }
        }

        async selectPayMethodsFromChild(payMethods: PayMethod[] | undefined){
            if (payMethods === undefined){
                showToast('Ha ocurrido un error. Intente de nuevo','error')
            }
            else 
            this.payMethods = await payMethods;
        }

        resetFields(){
                this.newBeneficiary = {nickname:null, owner_name: null, identification: null, account: null,
                                    account_type: null, phone_number: null, relation_type:null,
                                    email: null, name_bank: null, name_pay_method: null,id_pay_method: null,
                                    id_bank: null,id_doc_type: null,notification:{email_notif:null,phone_notif:null,address_notif:null,city_notif:null},}
                this.extraData= {
                    selectedCountryCurrency: {iso_cod: null,id_country: null,id_currency: null,name_country: null,country_iso_code: null,viewing_name: null,},
                    selectedPayMethod: {idPayMethod: null, name: null, fields:[]},
                    selectedBank: {idBank:-1,name:''},
                    selectedDocCountry:{id_all_country:'',country_iso_code: '',is_latin:false},
                    selectedDocType:{id_ident_doc_type:'', acronym:'', name_doc_type:'', id_resid_country:-1,type_doc_type:'', id_ip_country:-1, name_country:''},
                    selectedVenezuelanCIType: null
                }
        }

        @Watch('show')
        sendToParent(newVal: boolean){
            this.$emit('changeModalStatus', newVal)
        }
        closeModal(){
            this.show = false;
        }
    /* END MODAL */

    /* STEPPER */
    nextStep(){
        let invalid = this.$refs.component.validate();
        if (!invalid){
            this.$refs.step.moveStep(this.currentStep+1);
            this.currentStep += 1;
            this.currentComponent= this.steps[this.currentStep-1].component;
        }
    }

    get stepsLength(){
       return this.steps.length;
    }

    checkBeneficiary(beneficiary: FrequentBeneficiary){
        if (((this.newRemittance.beneficiariesInfo.beneficiaries.filter(el=> el.frequent.value !== true).length + this.filteredBeneficiaries.filter(el=> {if (el.check) return el}).length) < this.maxBeneficiariesPerRemittance) || beneficiary.check)
            beneficiary.check = !beneficiary.check;
        else showToast(`Puede seleccionar hasta ${this.maxBeneficiariesPerRemittance} beneficiarios`,'error')
        this.$forceUpdate()
    }


    removeLastStep(){
        this.steps.pop()
    }

    enableNextStep(newVal: boolean){
        this.nextStepEnabled = newVal
    }

    prevStep(){
        this.$refs.step.moveStep(this.currentStep-1);
        this.currentStep -= 1;
        this.currentComponent= this.steps[this.currentStep-1].component;
    }
    /* END STEPPER */

    error(){
        showToast('Ha ocurrido un error','error');
        this.show = false;
    }

    setFrequent(newVal: boolean){
        this.extraData.frequent = newVal
    }

    async submit(){
        let invalid = this.$refs.component.validate();
        if (!invalid){
            try{
                this.$refs.loader.showLoader();
                this.newBeneficiary.id_bank = this.extraData.selectedBank.idBank;
                this.newBeneficiary.id_pay_method = this.extraData.selectedPayMethod.idPayMethod;
                this.newBeneficiary.name_bank = this.extraData.selectedBank.name;
                this.newBeneficiary.name_pay_method = this.extraData.selectedPayMethod.name;
                if (this.newBeneficiary.id_bank === -1) this.newBeneficiary.id_bank = null; //fix this
                let response = undefined;
                if (this.showModal.type !== 'addToList'){
                    if (this.showModal.type === 'create')
                        response = await this.createFrequentBeneficiary({emailObj: {email_user: this.userData.email_user},beneficiary: this.newBeneficiary,docTypePrefix: this.extraData.selectedVenezuelanCIType})
                    else if (this.showModal.type === 'edit')
                        response = await this.updateBeneficiary({beneficiaryId: parseInt(this.newBeneficiary.id_beneficiary),beneficiary: this.newBeneficiary})
                    this.$refs.loader.hideLoader();
                    if (response.error === false){
                        showToast(response.msg,'success');
                        this.$emit('refreshBeneficiaries')
                        this.show= false;
                        //push de beneficiarios a 
                    }else showToast(response.msg,'error')
                }else {
                    if (this.extraData.frequent){
                        this.newBeneficiary.id_country = this.extraData.selectedCountryCurrency.id_country.toString();
                        this.newBeneficiary.check = true;
                        response = await this.createFrequentBeneficiary({emailObj: {email_user: this.userData.email_user},beneficiary: this.newBeneficiary,docTypePrefix: this.extraData.selectedVenezuelanCIType})
                        this.$refs.loader.hideLoader();
                        if (response.error === false){
                            this.newBeneficiary.id_beneficiary = response.id_beneficiary;
                            showToast(response.msg,'success');
                            this.show= false;
                            this.$emit('addBeneficiary',{
                                beneficiary: this.newBeneficiary,
                                frequent: this.extraData.frequent,
                                add: true
                            })
                        }else showToast(response.msg,'error')
                    }
                    else{ 
                        this.$refs.loader.hideLoader();
                        if (this.tabIndex === 0){
                            this.$emit('addBeneficiary',{
                                beneficiary: this.newBeneficiary,
                                frequent: this.extraData.frequent
                            })
                            this.show= false;
                            this.extraData.frequent = false;
                        }
                    }
                }
            }
            catch(e){
                this.$refs.loader.hideLoader();
                showToast('Ha ocurrido un error','error')        
            }
        }
        if (this.tabIndex === 1 && this.showModal.type === 'addToList'){
            let checkedBen = this.filteredBeneficiaries.filter(el=> el.check === true);
            this.$emit('addFrequentBeneficiaries',checkedBen)
            this.show= false;
        }
        // this.$emit('selectCountry',this.selectedCountry)
        // this.$emit('goToSignUp');
    }

    /* BENEFICIARIES */
    @beneficiaries.Action(BeneficiariesMethods.actions.CREATE_FREQUENT_BENEFICIARY)
    createFrequentBeneficiary!: (params:{emailObj: any, beneficiary: FrequentBeneficiary,docTypePrefix: string}) => Promise<{msg: string, error: boolean,id_beneficiary: number}>
    @beneficiaries.Action(BeneficiariesMethods.actions.UPDATE_FREQUENT_BENEFICIARY)
    updateBeneficiary!: (params:{beneficiaryId: number,beneficiary:FrequentBeneficiary})=> Promise<{msg: string, error: boolean}>
    @beneficiaries.Getter(BeneficiariesMethods.getters.GET_FREQUENT_BENEFICIARIES)
    frequentBeneficiaries!: FrequentBeneficiary[];
    @beneficiaries.Getter(BeneficiariesMethods.getters.GET_SIMULATED_FREQUENT_BENEFICIARIES)
    simulatedFrequentBeneficiaries!: FrequentBeneficiary[];
    /* AUTH */
    @auth.Getter(AuthMethods.getters.GET_USER_DATA)
    userData!: User;
    /* PAYMETHODS */
    @payMethods.Action(PayMethodsMethods.actions.FETCH_PAY_METHOD_BY_ID)
    fetchPayMethodById!: (payMethodId: number) => Promise<PayMethod | undefined>
    @payMethods.Action(PayMethodsMethods.actions.FETCH_PAY_METHODS_BY_COUNTRY)
    fetchPayMethodsByCountry!:(data: any) => Promise<PayMethod[] | undefined>
    /* DOC TYPES */
    @docTypes.Action(DocTypesMethods.actions.FETCH_DOC_TYPE_BY_ID)
    fetchDocTypeById!:(docTypeId: number) => Promise<CountryDocType | undefined>
    /* COUNTRIES */
    @countries.Action(CountriesMethods.actions.FETCH_ORIGN_DEST_COUNTRY_CURRENCY)
    fetchCountriesCurrencies!: () => Promise<boolean>
    @countries.Action(CountriesMethods.actions.FETCH_ALL_COUNTRIES)
    fetchAllCountries!: () => Promise<boolean>;
    /* REMITTANCE */
    @remittance.Getter(RemittanceMethods.getters.GET_NEW_REMITTANCE)
    newRemittance!: NewRemittance;
    @remittance.Getter(RemittanceMethods.getters.GET_MAX_BENEFICIARIES_PER_REMITTANCE)
    maxBeneficiariesPerRemittance!: number;
}

