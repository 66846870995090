
import { Component, Vue, Watch } from 'vue-property-decorator';
/* STORE */
import store from "@/store";
import {beneficiaries,auth,remittance,rates, countries, docTypes} from '@/store/namespaces'
import BeneficiariesMethods from '@/store/beneficiaries/methods/beneficiaries.methods'
import AuthMethods from '@/store/auth/methods/auth.methods'
import RemittanceMethods from '@/store/remittance/methods/remittance.methods'
import RatesMethods from '@/store/rates/methods/rates.methods'
import CountriesMethods from '@/store/countries/methods/countries.methods'
import DocTypesMethods from '@/store/docTypes/methods/docTypes.methods'
/* INTERFACES */
import { FrequentBeneficiary } from '@/modules/beneficiaries/interfaces/beneficiaries.interfaces';
import { User } from '@/modules/auth/interfaces/user.interface';
import { NewRemittance } from '../interfaces/newRemittance.interface';
import { MaxAmmount } from '../interfaces/limits.interface';
import { Remittance } from '@/modules/remittance/interfaces/remittance.interface';
import { Rate } from '@/modules/rates/interfaces/rate.interface';
/* COMPONENTS */
import Stepper from '@/components/utilities/Stepper.vue';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import Calculator from '@/modules/remittance/components/Calculator.vue';
import ConfirmationModal from '@/components/utilities/ConfirmationModal.vue';
import Loader from '@/components/utilities/Loader.vue';
import Beneficiaries from '@/modules/remittance/components/sendRemittance/Beneficiaries.vue'
import Ammounts from '@/modules/remittance/components/sendRemittance/Ammounts.vue'
import AmmountsResume from '@/modules/remittance/components/sendRemittance/AmmountsResume.vue'
import PaymentProof from '@/modules/remittance/components/sendRemittance/PaymentProof.vue'
import DestinyCountry from '@/modules/remittance/components/sendRemittance/deprecatedDestinyCountry.vue'
import AccountList from '@/modules/remittance/components/sendRemittance/AccountList.vue'
import RemittanceData from '@/modules/remittance/components/sendRemittance/RemittanceData.vue'
import InfoModal from '@/components/utilities/InfoModal.vue'
import NoRemittancePopup from '@/modules/verificationLevels/components/NoRemittancePopup.vue'
import WelcomePopup from '@/modules/auth/components/WelcomePopup.vue';
import VerifPhonePopup from '@/modules/auth/components/VerifPhonePopup.vue';
import VerifLevelOneDataPopup from '@/modules/auth/components/VerifLevelOneDataPopup.vue';
/* FUNCTIONS */
import { largestNumber, showToast } from '@/utils/global-functions';
/* CONSTANTS */
import { REMITTANCE_TYPES } from '../constants/remittance.constants';
import { epochToDateAndHour } from '@/utils/date-functions';
import { PreRemittance } from '../interfaces/preRemittance.interface';
import { clearRemittance } from '../constants/remittance.emptyState.constant';
import { currencyConvertion, manualRateNames } from '@/modules/rates/functions/rates.functions';
import {RATE_CATEGORIES, RATE_OPERATIONS} from '@/modules/rates/constants/rate.constants';
import { RangeRate } from '@/modules/rates/interfaces/rangeRate.interface';

@Component({
    components: {
        Loader,
        Stepper,
        SvgIcon,
        Calculator,
        ConfirmationModal,
        Beneficiaries,
        Ammounts,
        AmmountsResume,
        AccountList,
        PaymentProof,
        DestinyCountry,
        RemittanceData,
        InfoModal,
        NoRemittancePopup,
        WelcomePopup,
        VerifPhonePopup,
        VerifLevelOneDataPopup
    },
})
export default class LevelOne extends Vue {

    $refs : any ={}; 
    steps : Array<{component: string, label?: string, alert?: boolean}>= [
        { component: 'RemittanceData',label: 'Monto'},
        { component: 'Beneficiaries',label:'Beneficiario'},
        { component: 'AmmountsResume',label:'Resumen'},
    ];
    ratesOperations = RATE_OPERATIONS;
    ratesCategories =RATE_CATEGORIES
    currentComponent:{component: string, label?: string, alert?: boolean} = {component:'',label:'',alert: false};
    currentStep = 1;
    deleteConfirmationModal = false;
    noRemittanceModal = false;
    welcomeModal = false;
    verifPhoneModal = false;
    verifLevelOneModal = false;
    loader: boolean = true;
    ratesByCountry : Rate[] = [];
    infoModal={
        open: false,
        title: 'La tasa aplicada a su operación ha cambiado, por favor, llene nuevamente los datos pertinentes'
    }
    remittanceUnable= {
        title: '',
        open: false
    }
    lastUpdate = null
    disabledButton = false;
    loadComponent = false;
    webpayToken = '';
    showWebpayForm = false;
    banner = process.env.VUE_APP_DOMAIN.includes('.es') ? 'CRIPTOEFECTIVO-EU' : 'CRIPTOEFECTIVO'

    /* COMPUTED */
    get continueButtonText(){
        let buttonText = 'Continuar'
        console.log('this.currentStep: ',this.currentStep)
        if (this.currentStep === this.steps.length) buttonText = 'Enviar'
        else if (this.newRemittance.depositMethod.name === 'Webpay' && this.currentStep === 2) buttonText = 'Pagar con Webpay'
        return buttonText
    }

    async created(){
        this.selectVieMode()
    }

    /* CHECK IF USER CAN SEND A REMITTANCE  */
    async mounted()
    {
        await this.fetchLastRemittances({email_user:this.userData.email_user, limit: 10, end_date: null, start_date: null, mode: 'app' });
        console.log('remittances: ',this.remittances)
        if (((this.userData.id_verif_level === 1 && this.userData.verif_level_apb === true) || this.userData.id_verif_level > 1)){
            switch (this.remittances.length)
            {
                case 0:
                    this.verifPhoneModal = true;
                    await this.mountedFunction();
                    break;
                case 1:
                    this.verifLevelOneModal = true;
                    await this.mountedFunction();
                    break;
                default:
                    this.welcomeModal = true;
                    await this.mountedFunction();
                    break;
            }
        }
        else this.noRemittanceModal = true;
    }

    async mountedFunction(){
        this.loader = true;
        this.loadComponent = false;
        try{
            /* FETCH PRE REMITTANCE */
            await this.fetchPreRemittance(this.userData.email_user)
            /* CLEANING REMITTANCE STORE */
            await this.clearRemittanceStore()
            /* IF PRE REMITTANCE, FILL REMITANCE STORE */
            if (this.preRemittance.was_expired === null && this.preRemittance.active === true){
                await this.setRemittance(this.preRemittance.pre_remittance);
                this.nextStep();
                this.nextStep();
            }
            this.newRemittance.email_user = this.userData.email_user;
            /* FETCH LIMITS, CHECKS IF USER HAS REACHED SOME LIMITS, AND OPENS MODAL */
            if (
                !await this.fetchLimitsInOriginCountry({idCountry: typeof this.userData.id_resid_country  === 'number' ? this.userData.id_resid_country : parseInt(this.userData.id_resid_country),idVerifLevel: typeof this.userData.id_verif_level === 'number' ? this.userData.id_verif_level : parseInt(this.userData.id_verif_level) }) ||
                !await this.fetchLimits(this.userData.cust_cr_cod_pub)){
                throw new Error;
            }else this.isAllowedToSendRemittance(); 
            /* FETCHS COUNTRIES-CURRENCIES, BENEFICIARIES AND RATE TYPES */
            if (
            !await this.fetchCountriesCurrencies() ||
            !await this.fetchFrequentBeneficiaries({email_user: this.userData.email_user}) ||
            !await this.fetchRateTypes()
            ) {
                throw new Error;
            }
            /* FETCH RANGE RATES */
            if (!await this.fetchRangeRates()) {
                throw new Error;
            }
            this.loadComponent = true;
        }
        catch(e){
             this.$router.push({name: 'ErrorPage'}).catch();
        }
    }

    /* CHECKS IF USER HAS REACHED SOME LIMITS, AND OPENS MODAL */
        isAllowedToSendRemittance(){
            if (!this.limitsInOriginCountry.monthlyAmount && this.totalAcumPercentage >= 100) {
                this.remittanceUnable.title= 'Límite de operaciones alcanzado. Aumenta tu nivel de verificación para realizar más operaciones.';
                this.remittanceUnable.open = true;
            }
            else if (this.limitsInOriginCountry.monthlyAmount && this.monthlyAcumPercentage >=100) {
                this.remittanceUnable.title= 'Límite de volumen mensual alcanzado. Aumenta tu nivel de verificación para realizar más operaciones.';
                this.remittanceUnable.open = true;
            }
            else if (this.limitsInOriginCountry.monthlyAmount && this.weeklyAcumPercentage >=100) {
                this.remittanceUnable.title= 'Límite de volumen mensual alcanzado. Aumenta tu nivel de verificación para realizar más operaciones.';
                this.remittanceUnable.open = true;
            }
        }

    /* DEPRECATED, WAS USED FOR CAPTURE MODE */

        selectVieMode(){
            this.steps=[
                { component: 'RemittanceData',label: 'Monto'},
                { component: 'Beneficiaries',label:'Beneficiario'},
                { component: 'AmmountsResume',label:'Resumen'},
            ]
            // if (this.$route.params.modo === REMITTANCE_TYPES.WITH_CAPTURE){
            //     this.steps.unshift({ component: 'PaymentProof',alert:true });
            // }
            // else if (this.$route.params.modo === REMITTANCE_TYPES.NO_CAPTURE){
                this.steps.splice(2, 0,{component: 'PaymentProof',label: 'Pago'})
            // }
            this.currentComponent = this.steps[0];
        }

        @Watch('$route')
        async watchRoute(){
            this.selectVieMode();
            this.ratesByCountry = []
            this.currentStep = 1;
            this.$refs.step.moveStep(this.currentStep);
            this.currentComponent = this.steps[0];
            await this.mountedFunction();
        }

    /* END. DEPRECATED, WAS USED FOR CAPTURE MODE */

    /* CANCEL A PRE REMITTANCE */

    async cancelRemitanceConfirmed(){
        this.deleteConfirmationModal = false;
        this.$refs.loader.showLoader();
        /* CLEANING REMITTANCE STORE */
        await this.clearRemittanceStore()
        if (this.preRemittance.was_expired === null && this.preRemittance.active === true){
                if (await this.cancelPreRemitance(this.preRemittance.id_pre_remittance))
                    showToast('Operación cancelada exitosamente','success')
                else showToast('Ha ocurrido un error cancelando la operación','success')
        }
        this.$refs.loader.hideLoader();
        this.$router.push({name: 'Dashboard'}).catch();
    }

    /* CLEANING REMITTANCE STORE */
        async clearRemittanceStore(){
            let clearedRemittance: NewRemittance = clearRemittance(this.newRemittance)
            await this.setRemittance(clearedRemittance)
            // await store.commit('remittance/RESET_REMITTANCES_DATA', null, { root: true });
        }


    /* MODALS */
        deleteConfirmationModalStatus(newVal: boolean){
            this.deleteConfirmationModal = newVal;
        }
        informationModalStatus(newVal: boolean){
            this.infoModal.open = newVal;
        }
        remittanceUnableModalStatus(newVal: boolean){
            this.remittanceUnable.open = newVal;
            if (newVal === false){
                this.$router.push({name: 'Dashboard'}).catch();
            }
        }
        noRemittanceModalStatus(newVal: boolean){
            this.noRemittanceModal = newVal;
        }
        welcomeModalStatus(newVal: boolean){
            this.welcomeModal = newVal;
        }
        verifPhoneModalStatus(newVal: boolean){
            this.verifPhoneModal = newVal;
        }
        verifLevelOneModalStatus(newVal: boolean){
            this.verifLevelOneModal = newVal;
        }
     /* END MODALS */

    /* STEPPER */

        nextStep(){
        // let invalid = this.$refs.component.validate();
        // if (!invalid){
                this.$refs.step.moveStep(this.currentStep+1);
                this.currentStep += 1;
                this.currentComponent= this.steps[this.currentStep-1];//.component;
        //  }
        }

        toSomeStep(step: number){
            if (this.currentStep !== step){
                this.$refs.step.moveStep(step-1);
                this.currentStep = step;
                this.currentComponent= this.steps[step-1];//.component;
            }
        }

        prevStep(){
            if (this.currentStep !== 1){
                this.$refs.step.moveStep(this.currentStep-1);
                this.currentStep -= 1;
                this.currentComponent= this.steps[this.currentStep-1];//.component;
            }else this.$router.push({name: 'Dashboard'}).catch();
        }

        async validateNextStep(){
            this.loader = true;
            console.log('this.$refs.component: ',this.$refs.component)
            this.$refs.component.nextStep();
            console.log('this.newRemittance.depositMethod: ',this.newRemittance.depositMethod)
            console.log('this.newRemittance.bank: ',this.newRemittance.bank)
            console.log('this.newRemittance.beneficiariesInfo: ',this.newRemittance.beneficiariesInfo)
            if (this.newRemittance.depositMethod && this.newRemittance.depositMethod.name === 'Webpay' && this.newRemittance.bank && this.newRemittance.beneficiariesInfo && this.newRemittance.beneficiariesInfo.beneficiaries.length > 0){
                if (await this.createWebpayTransaction({amount: this.newRemittance.totalOriginRemittance})) {
                    console.log('this.webpayInfo: ',this.webpayInfo)
                    this.webpayToken = this.webpayInfo.token;
                    this.$nextTick(() => {
                        this.$refs.submitWebpay.click();
                    });
                }
            }
            this.loader = false;
        }

    /* END. STEPPER */

    /* COMPONENT LOADER */

        loaderOn(){
            this.loader = true;
            this.$forceUpdate()
        }

        loaderOff(){
            this.loader = false;
            this.$forceUpdate()
        }

    /* END. COMPONENT LOADER */

    setDisabled(value: boolean){
        this.disabledButton = value
        this.$forceUpdate()
    }


    /* PERCENTAGES */
        get monthlyAcumPercentage(){
            return (this.monthlyAcumInOriginCountry*100/this.limitsInOriginCountry.monthlyAmount)
        }
        get weeklyAcumPercentage(){
            return (this.weeklyAcumInOriginCountry*100/this.limitsInOriginCountry.weeklyAmount)
        }
        get totalAcumPercentage(){
            return (this.totalAcumInOriginCountry*100/this.limitsInOriginCountry.weeklyAmount)
        }
    /* ACUMS */
        get monthlyAcumInOriginCountry(){
            if (this.isSendingFromOtherCountry)
                return currencyConvertion(this.newRemittance.localCurrencyRateValue,this.monthlyAcum)
            else return this.monthlyAcum
        }
        get weeklyAcumInOriginCountry(){
            if (this.isSendingFromOtherCountry)
                return currencyConvertion(this.newRemittance.localCurrencyRateValue,this.weeklyAcum)
            else return this.weeklyAcum
        }
        get totalAcumInOriginCountry(){
            if (this.isSendingFromOtherCountry)
                return currencyConvertion(this.newRemittance.localCurrencyRateValue,this.totalAcum)
            else return this.totalAcum
        }
        get isSendingFromOtherCountry(){
                if (parseInt(this.newRemittance.originCountry.idCountry) !== this.userData.id_resid_country) return true
                else return false
        }

    /* MAX AMOUNTS */
        get maxAmmountWeeklyCurrency(){
            if (this.limitsInOriginCountry)
                return this.$options.filters.currency(this.limitsInOriginCountry.weeklyAmount)
            else return this.$options.filters.currency(0)
        }
        get maxAmmountMonthlyCurrency(){
            if (this.limitsInOriginCountry)
                return this.$options.filters.currency(this.limitsInOriginCountry.monthlyAmount)
            else return this.$options.filters.currency(0)
        }
    /* REMITTANCE MIN AMOUNT */
        get minAmmountInOriginCountry(){
            if (this.rangeRates.length > 0 && this.newRemittance.originCountry.idCountry)
                return  this.$options.filters.currency( this.rangeRates.find(el=> el.id_currency_origin === this.newRemittance.countryCurrency.idCourrency && el.id_currency_destiny === this.newRemittance.beneficiariesInfo.destinationCurrency.idCourrency).min_amount)
            else return undefined
        }


    @Watch('userRates')
    ratesUpdate(){
        if (this.userRates.type === RATE_CATEGORIES.MANUAL || this.userRates.type === RATE_CATEGORIES.AUTOMATIC){
           if (this.userRates.rates[0]) 
                this.ratesByCountry = this.userRates.rates;
           else {this.ratesByCountry = []; this.ratesByCountry.push(this.userRates.rates);}
        }
        else if (this.userRates.type === RATE_CATEGORIES.SPECIAL || this.userRates.type === RATE_CATEGORIES.VIP){
            this.ratesByCountry = []; this.ratesByCountry.push(this.userRates.rates)
        }
       this.lastUpdate = epochToDateAndHour(largestNumber(this.ratesByCountry,'date_last_modif'))
       if (!this.userRates.firstCall){
           this.infoModal.open = true;
           if (this.$route.params.modo === REMITTANCE_TYPES.NO_CAPTURE) this.toSomeStep(1);
           else this.toSomeStep(2);
           this.newRemittance.beneficiariesInfo.beneficiaries.forEach(el=>{
              el.ammount.value =0;
              el.originAmmount.value = 0
          })
       }
    }

    getManualRateNames(name: string){
       return manualRateNames(name).name;
    }

    @auth.Getter(AuthMethods.getters.GET_USER_DATA)
    userData! : User;  
    @auth.Getter(AuthMethods.getters.HAS_MIGRATED_EXCEPTION)
    hasMigratedException!: boolean;
    /* BENEFICIARIES */
    @beneficiaries.Action(BeneficiariesMethods.actions.FETCH_FREQUENT_BENEFICIARIES)
    fetchFrequentBeneficiaries!: (emailObj: any) => Promise<boolean>
    @beneficiaries.Getter(BeneficiariesMethods.getters.GET_FREQUENT_BENEFICIARIES)
    frequentBeneficiaries!: FrequentBeneficiary[]
    /* REMITTANCE */
    @remittance.Action(RemittanceMethods.actions.FETCH_REMITTANCES)
    fetchLastRemittances!: (data: {email_user: string, limit: number, end_date: number, start_date: number, mode: string}) => Promise<boolean>
    @remittance.Getter(RemittanceMethods.getters.GET_REMITTANCES)
    remittances!: Remittance[];
    @remittance.Action(RemittanceMethods.actions.GET_PRE_REMITTANCE)
    fetchPreRemittance!: (email_user: string)=> Promise<boolean>
    @remittance.Getter(RemittanceMethods.getters.GET_NEW_REMITTANCE)
    newRemittance!: NewRemittance;
    @remittance.Action(RemittanceMethods.actions.FETCH_LIMITS)
    fetchLimits!:(publicCode: string) => Promise<boolean>
    @remittance.Getter(RemittanceMethods.getters.GET_MAX_AMMOUNTS)
    maxAmmounts!: MaxAmmount;
    @remittance.Getter(RemittanceMethods.getters.GET_MONTHLY_ACUM)
    monthlyAcum!: number;
    @remittance.Getter(RemittanceMethods.getters.GET_WEEKLY_ACUM)
    weeklyAcum!: number;
    @remittance.Getter(RemittanceMethods.getters.GET_TOTAL_ACUM)
    totalAcum!: number;
    @remittance.Getter(RemittanceMethods.getters.GET_RESID_COUNTRY_CURRENCY)
    residCountryCurrency!: string;
    @remittance.Action(RemittanceMethods.actions.SET_REMITTANCE)
    setRemittance!:(remittance: NewRemittance)=> Promise<boolean>
    @remittance.Getter(RemittanceMethods.getters.GET_PRE_REMITTANCE)
    preRemittance!: PreRemittance;
    @remittance.Action(RemittanceMethods.actions.CANCEL_PRE_REMITTANCE)
    cancelPreRemitance!:(pre_remittance_id: number)=> Promise<boolean>
    @remittance.Action(RemittanceMethods.actions.FETCH_WEEKLY_AND_MONTHLY_LIMITS_BY_COUNTRY)
    fetchLimitsInOriginCountry!: (data:{idCountry: number, idVerifLevel: number})=> Promise<boolean>
    @remittance.Getter(RemittanceMethods.getters.GET_WEEKLY_AND_MONTHLY_LIMITS_BY_COUNTRY)
    limitsInOriginCountry!: {weeklyAmount: number, monthlyAmount: number, countryId: number}
    @remittance.Action(RemittanceMethods.actions.CREATE_WEBPAY_TRANSACTION)
    createWebpayTransaction!: (data:{amount: number})=> Promise<boolean>
    @remittance.Getter(RemittanceMethods.getters.GET_WEBPAY_INFO)
    webpayInfo!: {token: string, url: string}
    /* RATES */
    @rates.Action(RatesMethods.actions.FETCH_RATE_TYPES)
    fetchRateTypes!:()=>Promise<boolean>
    @rates.Action(RatesMethods.actions.FETCH_RANGE_RATES)
    fetchRangeRates!:()=>Promise<boolean>
    @rates.Getter(RatesMethods.getters.GET_RANGE_RATES)
    rangeRates!: RangeRate[];
    @rates.Getter(RatesMethods.getters.GET_USER_RATES)
    userRates!: {type: string, rates: any, firstCall: boolean}
    /* COUNTRIES */
    @countries.Action(CountriesMethods.actions.FETCH_ORIGN_DEST_COUNTRY_CURRENCY)
    fetchCountriesCurrencies!: ()=> Promise<boolean>
    /* DOC TYPES */
    @docTypes.Action(DocTypesMethods.actions.FETCH_DOC_TYPES)
    fetchDocTypes!: () => Promise<boolean>
}
