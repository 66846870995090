
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
/* STORE */
import {remittance,rates,auth} from '@/store/namespaces'
import RemittanceMethods from '@/store/remittance/methods/remittance.methods'
import AuthMethods from '@/store/auth/methods/auth.methods'
import RatesMethods from '@/store/rates/methods/rates.methods'
/* INTERFACES */
import { User } from '@/modules/auth/interfaces/user.interface';
import { NewRemittance } from '../../interfaces/newRemittance.interface';
import { RateType } from '@/modules/rates/interfaces/rateType.interface';
import { RangeRate } from '@/modules/rates/interfaces/rangeRate.interface';
import { Rate } from '@/modules/rates/interfaces/rate.interface';
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import Calculator from '@/modules/remittance/components/Calculator.vue';
import ConfirmationModal from '@/components/utilities/ConfirmationModal.vue';
import Loader from '@/components/utilities/Loader.vue';
/* DIRECTIVES */
import {VMoney} from 'v-money'
/* FUNCTIONS */
import {currencyConvertion, validateRemittanceRate} from '@/modules/rates/functions/rates.functions';
import { showToast } from '@/utils/global-functions';
import { MaxAmmount } from '../../interfaces/limits.interface';
@Component({
    components: {
        Loader,
        SvgIcon,
        Calculator,
        ConfirmationModal
    },
    directives:{
        money: VMoney
    }
})
export default class Ammounts extends Vue {

    remittanceOriginTotalFormatted={
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
          precision: 2,
          masked: true 
    }

    rateFormatted={
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
          precision: 0,
          masked: true 
    }
    rateInfo : {rateType: RateType | null, rangeRate: RangeRate | null} = {rateType: null, rangeRate: null}

    remittanceOriginTotal : number = 0;

    mounted(){
       this.remittanceOriginTotalFormatted.suffix = ' '+this.newRemittance.countryCurrency.isoCode;
       this.$socket.client.on('get_rate',this.getRate);
    }

    @Watch('remittanceOriginTotal')
    async sendRateType(newVal: string){
        let ammount: string = newVal.replace(this.remittanceOriginTotalFormatted.suffix,'')
        ammount = ammount.replaceAll('.', '').replace(',','.')
        this.newRemittance.totalDeposited = parseFloat(ammount)
       this.rateInfo = validateRemittanceRate(this.newRemittance.countryCurrency.idCourrency,this.newRemittance.beneficiariesInfo.destinationCurrency.idCourrency,this.rateTypes,this.rangeRates,this.newRemittance.totalDeposited)
        this.newRemittance.rateType = this.rateInfo.rateType;
        if (this.rateInfo.rateType)
            this.$socket.client.emit('get_rate',{
                id_currency_origin: this.newRemittance.countryCurrency.idCourrency,
                id_currency_destiny: this.newRemittance.beneficiariesInfo.destinationCurrency.idCourrency,
                id_country_origin: this.newRemittance.originCountry.idCountry,
                id_country_destiny: this.newRemittance.beneficiariesInfo.destinationCountry.idCountry,
                id_rate_type: this.rateInfo.rateType.idRateType,
                email_user: this.userData.email_user
            })
        else {
            this.newRemittance.rateValue = {operation: '',rate_factor:0}
        }
    }

    get totalDistributed(){
        return this.newRemittance.beneficiariesInfo.beneficiaries.map(item => item.ammount.value).reduce((prev, curr) => prev + curr, 0);
    }

    get toAssign(){
        if ((this.newRemittance.totalDeposited-this.totalDistributed) >0)
            return this.newRemittance.totalDeposited-this.totalDistributed
        else return 0
    }

    async getRate(newVal: any){
        this.newRemittance.rateValue = newVal
    }

    get remittanceDestinyTotal(){
        return currencyConvertion(this.newRemittance.rateValue,this.newRemittance.totalDeposited)
    }

    async nextStep() {
        this.newRemittance.totalOriginRemittance = this.newRemittance.totalDeposited - this.newRemittance.totalComission;
        this.newRemittance.totalDestinationRemittance = this.remittanceDestinyTotal;
        if (this.newRemittance.totalDeposited < this.minAmmountInRemittance.value)
           showToast(`El monto mínimo de envío es ${this.$options.filters.currency(this.minAmmountInRemittance.value)}${this.minAmmountInRemittance.currency}`,'error')
        else if ((this.newRemittance.totalDeposited + this.weeklyAcum) > this.maxAmmounts.weeklyAmmount)
           showToast(`El monto a enviar supera el límite semanal`,'error')
        else if ((this.newRemittance.totalDeposited + this.monthlyAcum) > this.maxAmmounts.monthlyAmmount)
           showToast(`El monto a enviar supera el límite mensual`,'error')
        else if (this.newRemittance.beneficiariesInfo.beneficiaries.some(el => el.ammount.value === 0
            || this.totalDistributed < this.newRemittance.totalDeposited)){
           showToast('Debe distribuir el monto total entre todos los beneficiarios','error')
        }
        else if (this.totalDistributed > this.newRemittance.totalDeposited)
           showToast('El monto distribuido entre los beneficiarios supera el total a enviar','error')
        else {
            this.$emit('nextStep')
        }
    }

    /* REMITTANCE */
    @remittance.Getter(RemittanceMethods.getters.GET_NEW_REMITTANCE)
    newRemittance!: NewRemittance;
    @remittance.Getter(RemittanceMethods.getters.GET_MAX_AMMOUNTS)
    maxAmmounts!: MaxAmmount;
    @remittance.Getter(RemittanceMethods.getters.GET_WEEKLY_ACUM)
    weeklyAcum!: number;
    @remittance.Getter(RemittanceMethods.getters.GET_MONTHLY_ACUM)
    monthlyAcum!: number;
    /* RATES */
    @rates.Getter(RatesMethods.getters.GET_RATE_TYPES)
    rateTypes!: RateType[];
    @rates.Getter(RatesMethods.getters.GET_RANGE_RATES)
    rangeRates!: RangeRate[];
    @rates.Getter(RatesMethods.getters.GET_MIN_AMMOUNT_IN_REMITTANCE)
    minAmmountInRemittance!: {value:number,currency:string};   
    /* AUTH */
    @auth.Getter(AuthMethods.getters.GET_USER_DATA)
    userData!: User;

}
