
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  /* CONSTANTS */
  /* INTERFACES */
  /* FUNCTIONS */
  /* COMPONENTS */
  import SvgIcon from '@/components/utilities/SvgIcon.vue';
  import { showToast } from '@/utils/global-functions';
  import { REMITTANCE_TYPES } from '@/modules/remittance/constants/remittance.constants';
  
  @Component({
      components:{
          SvgIcon
      }
  })
  export default class WelcomePopup extends Vue {
      @Prop() showModal!: boolean;
      @Prop() noClose?: boolean;
      @Prop() type!: string;
      @Prop() xColor!: string;
      show: boolean = this.showModal ? this.showModal : false;
  
      /* MODAL */
          @Watch('showModal')
          async renderModal(){
              this.show = this.showModal
          }
  
          @Watch('show')
          sendToParent(newVal: boolean){
              this.$emit('changeModalStatus', newVal)
          }
      /* END MODAL */
  
  }
  
  