
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
/* DIRECTIVES */
import {VMoney} from 'v-money'
import { Rate } from '@/modules/rates/interfaces/rate.interface';
import { currencyConvertion, reverseCurrencyConvertion } from '@/modules/rates/functions/rates.functions';


@Component({
    components: {
        SvgIcon
    },
    directives:{
        money: VMoney
    }
})
export default class StaticCalculator extends Vue {
    @Prop() label? : boolean;
    @Prop() origin!:{country: string,
                    currency: string,
                    disabledInput: boolean,
                    disabledDrowdown:boolean}
    @Prop() destiny!:{country: string,
                    currency: string,
                    disabledInput: boolean,
                    disabledDrowdown:boolean}
    @Prop() align?: string;
    @Prop() originAmmount!: number;
    @Prop() destinyAmmount!: number;
    @Prop() cols?: string;
    @Prop() sm?: string;
    @Prop() md?: string;
    @Prop() lg?: string;
    formattedCurrency={
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
        //  precision: 6,
          masked: true 
    }
    countries = [
        {name: 'chile', iso_code:'cl',text: 'PEN'},
        {name: 'venezuela',iso_code:'ve', text: 'VEF'}
    ]

    mounted(){
    }

    get fdestinyAmmount() {
       // if (this.destinyAmmount !== 0)
          return this.formatInput(this.destinyAmmount);
    }
    get foriginAmmount() {
       // if (this.destinyAmmount !== 0)
          return this.formatInput(this.originAmmount);
    }

    formatInput(num) {
       // if (num.toString().match(/^[0-9\.,]+/)){
            let array = num.toString().split('.')
            let result =  array[0].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1\.`)
            if (array[1]){
                result=result+','+array[1].match(/\d{0,6}/);
            }
            return result
       //return Number(num);
    }


}
