
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { required, requiredIf} from 'vuelidate/lib/validators';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import { FrequentBeneficiary } from '../../interfaces/beneficiaries.interfaces';
import {countries,docTypes} from '@/store/namespaces';
/* STORE METHODS */
import CountriesMethods from '@/store/countries/methods/countries.methods';
import DocTypesMethods from '@/store/docTypes/methods/docTypes.methods';
/* INTERFACES */
import { Country, CountryCurrency, NationalityCountry } from '@/modules/countries/interfaces/countries.interface';
import {CountryDocType} from '@/modules/docTypes/interfaces/docType.interface';
import { Currency } from '@/modules/currencies/interfaces/currencies.interface';
import { PayMethod } from '@/modules/payMethods/interfaces/payMethods.interface';
import { Bank } from '@/modules/banks/interfaces/bank.interface';
/* CONSTANTS */
import { showToast } from '@/utils/global-functions';
import { searchFilter } from '@/utils/filter.functions';
import {BENEFICIARY_RELATIONSHIP,BENEFICIARY_TYPES,BENEFICIARY_TYPE} from '@/modules/beneficiaries/constants/beneficiaries.constants';
import {VENEZUELAN_CI_TYPES} from '@/modules/docTypes/constants/docTypes.contants';
import {VENEZUELAN_RIF_TYPES} from '@/modules/docTypes/constants/docTypes.contants';
@Component({
    components: {
        SvgIcon,
    },
    mixins: [validationMixin],
    validations:{
        beneficiary:{
            owner_name:{required},
            identification:{required},
            relation_type:{required},
            nickname:{required: requiredIf(function(){
                        return this.type !== 'addToList' || (this.type === 'addToList' && this.frequent === true)
                })
            },
        },
        extraData:{
            // selectedDocCountry:{name_country:{required}},
            selectedDocType:{name_doc_type:{required}},
            selectedVenezuelanCIType:{
                required: requiredIf(function(){
                    if (this.isVenezuelanCI)
                        return true
                })
            }
        }
    }
})
export default class SecondStep extends Vue {
    @Prop() beneficiary!: FrequentBeneficiary;
    @Prop() extraData!: {selectedCountryCurrency:CountryCurrency,selectedPayMethod:PayMethod,selectedBank:Bank,selectedDocCountry: NationalityCountry,selectedDocType: CountryDocType, frequent?: boolean,selectedVenezuelanCIType?: string};
    @Prop() type!: string;
    @Prop() payMethods!: PayMethod[];
     loader = true;
    // selectedCountryDocTypes = []
    countrySearch = '';
    beneficiaryRelationship = BENEFICIARY_RELATIONSHIP;
    frequent: boolean = false;

    async mounted(){
        this.frequent =  this.extraData.frequent;
        if (this.type === 'edit'){
            this.extraData.selectedDocCountry.name_country =this.extraData.selectedDocCountry.country_name
            // this.getDocTypesByCountry();
        }
        else {
            if (this.extraData.selectedCountryCurrency.country_iso_code === 'VE'){
                // await this.selectCountryDocType(this.nationalityCountries.find(el=> el.country_iso_code === 'VE'))
                this.extraData.selectedDocType = this.docTypes.find(el=> el.acronym === 'CI')
                //@ts-ignore
                if (this.extraData.selectedDocType){
                    this.beneficiary.id_doc_type = this.extraData.selectedDocType.id_ident_doc_type; 
                    this.beneficiary.name_doc_type = this.extraData.selectedDocType.name_doc_type
                }
            }else{
               if (this.docTypes.length === 1) this.selectDocType(this.docTypes[0])
               // this.extraData.selectedDocCountry = {id_all_country:'', country_iso_code: '', is_latin:false}
               // this.extraData.selectedDocType = {id_ident_doc_type:'', acronym:'', name_doc_type:'', id_resid_country:-1,type_doc_type:'', id_ip_country:-1, name_country:''};
               // this.beneficiary.id_doc_type = null; this.beneficiary.name_doc_type = null
            }
        }
        this.loader = false;
    }

    setFrequent(){
        this.frequent = !this.frequent;
        this.$emit('setFrequent',this.frequent)
    }

    get beneficiaryNameLabel(){
        if (this.beneficiary.beneficiaryType === BENEFICIARY_TYPE.NATURAL)
            return 'Nombres y apellidos'
        else return 'Razón social'
    }

    // async selectCountryDocType(country: NationalityCountry){
    //     this.loader = true;
    //     this.extraData.selectedDocCountry = country;
    //     this.extraData.selectedDocType = {id_ident_doc_type:'', acronym:'', name_doc_type:'', id_resid_country:-1,type_doc_type:'', id_ip_country:-1, name_country:''}
    //     this.selectedCountryDocTypes = []
    //     await this.getDocTypesByCountry();
    //     this.loader = false;
    // }

    selectRelationship(relationship: string){
        this.beneficiary.relation_type = relationship;
        this.$forceUpdate();
    }

    async getDocTypesByCountry(){
        let countryId = await this.fetchCountryIdByName(this.extraData.selectedDocCountry.name_country)
        if (countryId.length){
            this.extraData.selectedDocCountry.id_all_country = countryId[0].id_ip_country;
            this.selectedCountryDocTypes = this.docTypes.filter(el=> el.name_country === this.extraData.selectedDocCountry.name_country)
        }
        else this.$emit('error')
    }

    selectDocType(docType: CountryDocType){
       this.extraData.selectedDocType = docType; 
       this.beneficiary.id_doc_type = docType.id_ident_doc_type; 
       this.beneficiary.name_doc_type = docType.name_doc_type; 
       if (this.isVenezuelanCI) this.extraData.selectedVenezuelanCIType = this.venezuelanCITypes[0]
       else this.extraData.selectedVenezuelanCIType = null;
    }

    validate(){
        this.$v.$touch();
        return this.$v.$invalid          
    }

    get isNumeric(){
        if (this.extraData.selectedDocType && this.extraData.selectedDocType.name_doc_type && this.extraData.selectedDocType.name_doc_type.includes('Pasaporte'))
            return false
        else return true
    }

    /* COUNTRY SEARCH */
    get criteria() {
        return this.countrySearch.trim().toLowerCase();
    }
    get availableOptions() {
        const criteria = this.criteria
        if (criteria) {
          return  searchFilter(this.nationalityCountries,['spanish_name','name_country'],this.criteria);
        }
        return this.nationalityCountries
    }

    get searchDesc() {
        if (this.criteria && this.availableOptions.length === 0) {
          return 'No se encontraron coincidencias'
        }
        return ''
    }

    get isVenezuelanDocType(){
        if (this.extraData.selectedDocType && this.extraData.selectedDocType.name_country === 'Venezuela')
            return true
        else return false
    }

    get isVenezuelanCI(){
        if (this.extraData.selectedDocType && this.extraData.selectedDocType.acronym === 'CI' && this.extraData.selectedDocType.name_country === 'Venezuela')
            return true
        else return false
    }

    get venezuelanCITypesList(){
        if (this.isVenezuelanCI) {
            this.venezuelanCITypes = VENEZUELAN_CI_TYPES;
            return this.venezuelanCITypes
        } else {
            this.venezuelanCITypes = VENEZUELAN_RIF_TYPES;
            return this.venezuelanCITypes
        }
    }


    @countries.Getter(CountriesMethods.getters.GET_ALL_COUNTRIES)
    nationalityCountries!: NationalityCountry[];
    @docTypes.Getter(DocTypesMethods.getters.GET_DOC_TYPES)
    docTypes!: CountryDocType[];
    @countries.Action(CountriesMethods.actions.FETCH_COUNTRY_ID_BY_NAME)
    fetchCountryIdByName!: (countryName: string) => Promise<any>; 
}
