
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
/* CONSTANTS */
/* INTERFACES */
/* FUNCTIONS */
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import { showToast } from '@/utils/global-functions';
import { REMITTANCE_TYPES } from '@/modules/remittance/constants/remittance.constants';
import CodeInput from 'vue-verification-code-input';
import VuePhoneNumberInput from 'vue-phone-number-input';
import { auth, users, verifLevels } from '@/store/namespaces';
import AuthMethods from '@/store/auth/methods/auth.methods';
import UsersMethods from '@/store/users/methods/users.methods';
import VerifLevelMethods from '@/store/verificationLevels/methods/verificationLevels.methods';
import { EditPhone } from '@/modules/verificationLevels/interfaces/requests.interface';

@Component({
    components: {
        SvgIcon,
        CodeInput,
        VuePhoneNumberInput,
    },
})
export default class VerifPhonePopup extends Vue {
    @Prop() showModal!: boolean;
    @Prop() noClose?: boolean;
    @Prop() userData!: any;
    @Prop() xColor!: string;
    show: boolean = this.showModal ? this.showModal : false;
    inputDisabled = false;
    mainTranslations: any = {
        countrySelectorLabel: 'Código país',
        countrySelectorError: 'Elige un país',
        phoneNumberLabel: '',
        example: 'Ejemplo :',
    };
    dropdownCountries: any = [];
    expiredCode = false;
    invalidCode = false;
    validCode = null;
    sended = false;
    loading = false;
    currentCode;

    /* MODAL */
    @Watch('showModal')
    async renderModal() {
        this.show = this.showModal;
    }

    @Watch('show')
    sendToParent(newVal: boolean) {
        this.$emit('changeModalStatus', newVal);
    }

    onChange(v?: any) {
        this.currentCode = v;
        this.expiredCode = false;
        this.invalidCode = false;
        if (v && v.length < 6) this.validCode = null;
    }

    async onComplete(code: any) {
        this.expiredCode = false;
        this.invalidCode = false;
        // if (this.$socket.disconnected) this.$socket.client.emit('new_connection',this.user.email_user)
        // this.$socket.client.emit('verif_code', {
        //     ident_user: this.levelOneRequirements.main_phone_full.value,
        //     code: code,
        //     email_user: this.user.email_user
        // })
        if (!this.sended) return;
        await this.sendCode(code, this.userData.main_phone_full);
    }

    updateMainPhone(payload: any) {
        if (payload.nationalNumber) {
            let formatNational = payload.nationalNumber
            if (formatNational && formatNational.charAt(0) === '0') {
                formatNational = formatNational.substring(1);
            }
            this.userData.main_phone_full =
                '+' + payload.countryCallingCode + formatNational;
            this.userData.main_phone = formatNational;
            this.userData.main_phone_code = '+' + payload.countryCallingCode;
        }
    }

    async sendSmsRequest() {
        this.onChange();
        this.sended = false;
        this.loading = true;
        let response = await this.sendSms({
            main_phone_full: this.userData.main_phone_full,
            first_name: this.userData.first_name,
            last_name: this.userData.last_name,
        });
        if (response.error) showToast(response.msg, 'error');
        else {
            showToast('Mensaje enviado satisfactoriamente', 'success');
            this.sended = true;
        }
        this.loading = false;
        this.expiredCode = false;
    }

    async sendCode(code: any, ident_user: string) {
        this.loading = true;
        let response = await this.validateCode({
            ident_user: ident_user,
            code: code,
        });
        if (!response)
            showToast('Ha ocurrido un error al verificar el código', 'error');
        this.isCodeValid(response);
        await this.submit();
        this.loading = false;
    }

    isCodeValid(response: { msg: string; ident_user: string }) {
        if (response.msg === 'Invalid user')
            showToast('Usuario inválido', 'error');
        if (response.msg === 'Valid code') {
            if (response.ident_user === this.userData.main_phone_full) {
                this.inputDisabled = true;
                this.validCode = true;
            }
        } else if (response.msg === 'Expired code') {
            if (response.ident_user === this.userData.main_phone_full) {
                this.expiredCode = true;
                this.invalidCode = false;
                this.validCode = false;
            }
        } else if (response.msg === 'Invalid code') {
            if (response.ident_user === this.userData.main_phone_full) {
                this.invalidCode = true;
                this.expiredCode = false;
                this.validCode = false;
            }
        } else if (response.msg === 'Time started') {
            if (response.ident_user === this.userData.main_phone_full) {
                this.sended = true;
            }
        }
    }

    async submit()
    {
        if (this.isValidCode) {
            this.loading = true
            await this.editClientPhone({
                uuid_user: this.userData.id_uuid,
                main_phone_full: this.userData.main_phone_full,
                main_phone_code: this.userData.main_phone_code,
                main_phone: this.userData.main_phone.replace(/\s+/g, ''),
            });
            showToast('Código correcto. Continúe con su remesa.', 'success');
            this.showModal = false;
            this.loading = false;
        } else
            showToast(
                'Código incorrecto o vencido. Vuelve a intentar',
                'error'
            );
    }

    get mainPhoneCountryCode() {
        return this.userData.iso_code_resid_country || 'CO';
    }

    get isValidCode() {
        // if (this.isUncompletedMigratedUser && this.validCode && this.validCodeEmail)
        //     return true
        // else if (!this.isUncompletedMigratedUser && this.validCode === null) return null
        // else if (!this.isUncompletedMigratedUser && this.validCode) return true
        if (this.validCode) return true;
        else if (!this.validCode === null) return null;
        else return false;
    }

    mounted() {
        this.sendSmsRequest();
        console.log(this.userData);
        this.dropdownCountries.push(this.userData.iso_code_resid_country);
    }
    /* END MODAL */

    @auth.Action(AuthMethods.actions.SEND_SMS)
    sendSms!: (data: {
        main_phone_full: string;
        first_name: string;
        last_name: string;
    }) => Promise<{ error: boolean; msg: string }>;
    @users.Action(UsersMethods.actions.VALIDATE_CODE)
    validateCode!: (data: {
        ident_user: string;
        code: string;
    }) => Promise<{ msg: string; ident_user: string }>;
    @verifLevels.Action(VerifLevelMethods.actions.UPDATE_CLIENT_PHONE)
    editClientPhone!: (data: EditPhone) => Promise<boolean>;
}
