import { FrequentBeneficiary } from "../interfaces/beneficiaries.interfaces";

export const FREQUENT_BENEFICIARY_EMPTY_STATE : FrequentBeneficiary ={
    check: false,
    email: null, 
    id_bank: null,
    account: null,
    nickname:null, 
    name_bank: null, 
    owner_name: null, 
    id_country: null,
    account_type: null, 
    phone_number: null, 
    id_pay_method: null,
    identification: null, 
    name_pay_method: null,
    id_optional_field: null,
    name_optional_field: null,
    relation_type: null,
    beneficiaryType: null,
    notification:{
        email_notif:null,
        phone_notif:null,
        address_notif: null,
        city_notif: null
    }
};    