
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
/* VUELIDATE */
import { validationMixin } from 'vuelidate';
import { required} from 'vuelidate/lib/validators';
/* STORE */
import {auth, countries,currencies, remittance} from '@/store/namespaces';
import CountriesMethods from '@/store/countries/methods/countries.methods';
import CurrenciesMethods from '@/store/currencies/methods/currencies.methods';
import RemittanceMethods from '@/store/remittance/methods/remittance.methods';
import AuthMehods from '@/store/auth/methods/auth.methods';
/* INTERFACES */
import { Country } from '@/modules/countries/interfaces/countries.interface';
import {NewRemittance} from '@/modules/remittance/interfaces/newRemittance.interface';
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import Loader from '@/components/utilities/Loader.vue';
/* FUNCTIONS */
import { showToast } from '@/utils/global-functions';
import { Currency } from '@/modules/currencies/interfaces/currencies.interface';
/* CONSTANTS */
import {NEW_REMITTANCE_EMPTY_STATE} from '@/modules/remittance/constants/remittance.emptyState.constant'
import { User } from '@/modules/auth/interfaces/user.interface';
@Component({
    components: {
        Loader,
        SvgIcon,
    },
    mixins: [validationMixin],
    validations:{
        // remittance:{currency_id:{required}},
        newRemittance:{
            beneficiariesInfo:{destinationCurrency:{idCourrency:{required}}},
            countryCurrency:{required}
        },
    }
})
export default class DestinyCountry extends Vue {

    selectedCountry : Partial<Country> = {};
    selectedCurrency: Currency = {idCourrency: null, name:'', isoCode:'', type:''}
    selectedOriginCurrency: Currency = {idCourrency: null, name:'', isoCode:'', type:''}
    currencies: Currency[] = []
    originCurrencies: Currency[] = []
    showCurrencyModal : boolean = false;
    $refs : any = {};

    async  mounted(){
        await this.fetchDestinyCountries();
      //  this.europeCountries = this.destinyCountries.filter(el => el.country_iso_code === 'ES')
    }

    async selectCountry(country: Country) {
        if (country.id_country !== "-1"){
            this.selectedCountry = country;
            this.newRemittance.beneficiariesInfo.destinationCountry={
                idCountry : country.id_country,
                name: country.name_country,
                code: country.country_iso_code
            }
            this.selectedCurrency = {idCourrency: null, name:'', isoCode:'', type:''}
        }
    }

    selectDestinationCurrency(currency: Currency){
        this.selectedCurrency = currency; 
        this.newRemittance.beneficiariesInfo.destinationCurrency={
            idCourrency: currency.idCourrency,
            name: currency.name,
            isoCode: currency.isoCode,
            type: currency.type
        }
        // remittance.currency_id = currency.idCourrency;
    }
    selectOriginCurrency(currency: Currency){
        this.selectedOriginCurrency = currency; 
        this.newRemittance.countryCurrency={
            idCourrency: currency.idCourrency,
            name: currency.name,
            isoCode: currency.isoCode,
            type: currency.type
        }
    }



    validate(){
        this.$v.$touch()
        if (!this.$v.$invalid){
            this.$emit('nextStep')
        }    
    }

    /* MODAL */
    // changeEuropeCountriesModalStatus(newVal: boolean) {
    //     this.europeCountriesModal = newVal;
    // }
    /* END MODAL */

   async nextStep() {
        if (this.selectedCountry.id_country) {
            this.$refs.loader.showLoader();
            this.currencies = await this.fetchCurrencies({country_id: this.selectedCountry.id_country,origin: false})
            this.originCurrencies = await this.fetchCurrencies({country_id: this.userData.id_resid_country,origin: true})
            this.$refs.loader.hideLoader();
            this.showCurrencyModal = true;
        } else {
            showToast('Seleccione el pais de destino','error')
        }
    }

    /* AUTH */
    @auth.Getter(AuthMehods.getters.GET_USER_DATA)
    userData!: User;
    /* COUNTRIES */
    @countries.Action(CountriesMethods.actions.FETCH_DESTINY_COUNTRIES)
    fetchDestinyCountries!: () => Promise<boolean>
    @countries.Getter(CountriesMethods.getters.GET_DESTINY_COUNTRIES)
    destinyCountries!: Country[];
    /* CURRENCIES */
    @currencies.Action(CurrenciesMethods.actions.FETCH_CURRENCIES_BY_COUNTRIES)
    fetchCurrencies!: (params: any) => Promise<Currency[]>
    /* REMITTANCE */
    @remittance.Getter(RemittanceMethods.getters.GET_NEW_REMITTANCE)
    newRemittance!: NewRemittance;
}
