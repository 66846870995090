
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
/* VUELIDATE */
import { validationMixin } from 'vuelidate';
import { required, requiredIf } from 'vuelidate/lib/validators';
/* STORE */
import store from '@/store';
import { auth , countries, docTypes, verifLevels} from '@/store/namespaces';
import DocTypesMethods from '@/store/docTypes/methods/docTypes.methods';
import AuthMethods from '@/store/auth/methods/auth.methods';
import CountryMethods from '@/store/countries/methods/countries.methods';
import VerifLevelMethods from '@/store/verificationLevels/methods/verificationLevels.methods';
/* INTERFACES*/
import { User } from '@/modules/auth/interfaces/user.interface';
import { NationalityCountry } from '@/modules/countries/interfaces/countries.interface';
import {CountryDocType} from '@/modules/docTypes/interfaces/docType.interface';
import { ReqLevelOne, RequestStatus } from '@/modules/verificationLevels/interfaces/requests.interface';

/* CONSTANTS */
import { USER_EMPTY_STATE } from '@/modules/auth/emptyStates/user.emptyState';
import {availableImagesTypes} from '@/utils/constants'
import { ALT_DOC_TYPES } from '@/modules/docTypes/constants/docTypes.contants'
/* FUNCTIONS*/
import { getSpanishLanCountries, showToast } from '@/utils/global-functions';
import {_arrayBufferToBase64, isPDF, createAndDownloadBlobFile } from '@/utils/image-functions'
import { searchFilter } from '@/utils/filter.functions';
/* COMPONENTS*/
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import InputFile from '@/components/utilities/InputFile.vue';
import Toast from '@/components/utilities/Toast.vue';
import ImageExplainedModal from '@/components/utilities/ImageExplainedModal.vue';
/* LIBRARIES */
import { getCountry } from 'country-list-spanish';

@Component({
    components: {
        SvgIcon,
        InputFile,
        Toast,
        ImageExplainedModal
    },
    mixins: [validationMixin],
    validations:{
        levelOneRequirements: {
            ident_doc_number:{
                value: {required}
            },
            id_ident_doc_type:{
                value: {required }
            },
            doc:{
                value: {required}
            },
            selfie: {
                value: {required}
            },
            id_nationality_country:{
                value: {required}
            },
            id_country: {
                value: {required: requiredIf(function(){
                    return !this.isFirstQuestion 
                })}
            }
        }
    }
})
export default class SecondStep extends Vue {
//    @Prop() levelOneRequirements!: any;
    @Prop() alreadyRequested?: boolean;
    docTypes : CountryDocType[] = []
    altDocTypes: Array<string> = Object.values(ALT_DOC_TYPES)
    formats: Array<string> = []
    user: User = USER_EMPTY_STATE;
    loader = true;
    docImageAlreadyExists = false
    selfieImageAlreadyExists = false
    isPdfDocImage = false
    isPdfSelfieImage = false
    countrySearch = ''
    currentNacionalityCountry = '';
    imageExplainedModal = {
        src: null,
        title: '',
        show: false
    };
    async mounted() {
        if (this.levelOneRequirements.doc.rejected === false){ 
            this.docImageAlreadyExists = true;
            this.isPdfDocImage = isPDF(this.levelOneRequirements.doc.value.data)
        }
        if (this.levelOneRequirements.selfie.rejected === false){ 
            this.selfieImageAlreadyExists = true;
            this.isPdfSelfieImage = isPDF(this.levelOneRequirements.selfie.value.data)
        }
        this.formats = availableImagesTypes;  
        this.user =  this.userData; 
        await this.fetchDocTypes({id_country: this.user.id_resid_country,person_type: 'Natural',signup_or_payment: 'signup'});
        if (!this.docType && this.levelOneRequirements.id_ident_doc_type.value){
            let altDocType = await this.fetchDocTypeById(this.levelOneRequirements.id_ident_doc_type.value);
            if (altDocType && altDocType.name_doc_type.includes(ALT_DOC_TYPES.PASSPORT.label)) this.levelOneRequirements.id_ident_doc_type.value = ALT_DOC_TYPES.PASSPORT.id
            else if (altDocType) this.levelOneRequirements.id_ident_doc_type.value = ALT_DOC_TYPES.ID.id
        }
        await this.fetchNationalityCountries();
        this.getMainDocs();  
        this.loader = false;
    }

    /* if first question = yes, then give me the main doc types for the dropdown  */
    getMainDocs(){
        //if (this.selectedDocType === 1) {
            this.docTypes = this.mainDocTypes.filter(el => el.id_resid_country === this.user.id_resid_country);
       // }
    }

    submit(){
        this.$v.$touch();
        if (!this.isFirstQuestion && !this.levelOneRequirements.id_country.value)
        this.levelOneRequirements.id_country.value = this.countryDocType.id_all_country
        if (!this.$v.$invalid){
            // if (!this.$v.levelOneRequirements.doc.value.$model.name && !this.docImageAlreadyExists) showToast('Debe anexar las imágenes requeridas','error')
            // else if (!this.$v.levelOneRequirements.selfie.value.$model.name && !this.selfieImageAlreadyExists) showToast('Debe anexar las imágenes requeridas','error')
            this.$emit('nextStep')  
        }
        // if (this.$v.levelOneRequirements.doc.value.$invalid || this.$v.levelOneRequirements.selfie.value.$invalid) showToast('Debe anexar las imágenes requeridas','error')
    }

    goBack(){
        this.$emit('prevStep')
    }

    downloadPDF(content: Array<number>, type: string){
        createAndDownloadBlobFile(content,`${type}-${this.user.cust_cr_cod_pub}`,'pdf')
    }

    getDocImage(image: string){
        this.levelOneRequirements.doc.value = image;
    }

    getSelfieImage(image: string){
        this.levelOneRequirements.selfie.value = image;
    }


    getImageSrc(imageContent: any){
        if (imageContent.data)
            return _arrayBufferToBase64(imageContent.data);
        return _arrayBufferToBase64(imageContent);
    }

    /* COUNTRY SEARCH */
    get criteria() {
        return this.countrySearch.trim().toLowerCase();
    }

    get availableOptions() {
        const criteria = this.criteria
        if (criteria) {
          return  searchFilter(this.nationalityCountries,['spanish_name'],this.criteria);
        }
        return this.nationalityCountries
    }

    get searchDesc() {
        if (this.criteria && this.availableOptions.length === 0) {
          return 'No se encontraron coincidencias'
        }
        return ''
    }

    get countryDocType(){
        if (this.isFirstQuestion) return this.nationalityCountries.find(el => el.country_iso_code === this.userData.iso_code_resid_country)
        else if (this.levelOneRequirements.id_country.value)
            return this.nationalityCountries.find(el => el.id_all_country === this.levelOneRequirements.id_country.value)
        else if (this.user.doc_type_name_country)
            return this.nationalityCountries.find(el => el.name_country.includes(this.user.doc_type_name_country))
    }

    get nationalityCountry(){
        if (this.levelOneRequirements.id_nationality_country.value)
            return this.nationalityCountries.find(el => el.id_all_country === this.levelOneRequirements.id_nationality_country.value.toString())
        else return null
    }

    get docType(){
        let selected
        if (!this.isFirstQuestion){
            selected = this.altDocTypes.find(el => el.id === this.levelOneRequirements.id_ident_doc_type.value)
            if (selected) return selected.label
        }
        else{
            selected = this.docTypes.find(el => el.id_ident_doc_type === this.levelOneRequirements.id_ident_doc_type.value)
            if (!selected) selected = this.secondaryDocTypes.find(el => el.id_ident_doc_type === this.levelOneRequirements.id_ident_doc_type.value)
        }
        if (selected) return selected.name_doc_type
        return null
    }

    get selectedDocType(): CountryDocType | null{
        if (this.levelOneRequirements.id_ident_doc_type.value) return this.docTypes.find(el=> el.id_ident_doc_type === this.levelOneRequirements.id_ident_doc_type.value)
        return null
    }

    get docNumberLabelException(){
        if (this.selectedDocType && this.selectedDocType.acronym === 'RUT') return 'RUT'
        return 'Documento'
    }

    get isFirstQuestion(){
        return parseInt(this.levelOneRequirements.id_ident_doc_type.value) >= 1
    }

    /* MODALS */
    imageExplainedModalStatus(newVal: boolean){
        this.imageExplainedModal.show = newVal;
    }

    /* AUTH */
    @auth.Getter(AuthMethods.getters.GET_USER_DATA)
    userData!: User;
    @auth.Action(AuthMethods.actions.SAVE_SELECTED_COUNTRY_ID_DOC)
    saveSelectedCountryIdDoc!: (countryId: string) => void;
    @auth.Action(AuthMethods.actions.SAVE_SELECTED_DOC_QUESTION_NUMBER)
    saveSelectedDocQuestion!: (docQuestionId: number) => void;
    @auth.Getter(AuthMethods.getters.GET_SELECTED_DOC_TYPE)
    userDocType!: number;
    /* DOC TYPES */
    @docTypes.Action(DocTypesMethods.actions.FETCH_DOC_TYPES)
    fetchDocTypes!: (data:{id_country: number, person_type: string, signup_or_payment: string}) => Promise<boolean>;
    @docTypes.Action(DocTypesMethods.actions.FETCH_DOC_TYPE_BY_ID)
    fetchDocTypeById!: (docTypeId: number) => Promise<CountryDocType | undefined>;
    @docTypes.Getter(DocTypesMethods.getters.GET_MAIN_DOC_TYPES)
    mainDocTypes!: CountryDocType[];
    @docTypes.Getter(DocTypesMethods.getters.GET_SECONDARY_DOC_TYPES)
    secondaryDocTypes!: CountryDocType[];
    /* COUNTRIES */
    @countries.Action(CountryMethods.actions.FETCH_COUNTRY_ID_BY_NAME)
    fetchCountryIdByName!:(countryName: string) => Promise<any>
    @countries.Action(CountryMethods.actions.FETCH_ALL_COUNTRIES)
    fetchNationalityCountries!: () => Promise<boolean>
    @countries.Getter(CountryMethods.getters.GET_ALL_COUNTRIES)
    nationalityCountries!: NationalityCountry[]
    /* VERIF LEVELS */
    @verifLevels.Getter(VerifLevelMethods.getters.GET_LEVEL_ONE_REQUEST)
    levelOneRequirements!: ReqLevelOne
}
