
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
/* VUELIDATE */
import { required, requiredIf } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
/* STORE */
import { auth, countries, verifLevels } from '@/store/namespaces';
import AuthMethods from '@/store/auth/methods/auth.methods';
import CountryMethods from '@/store/countries/methods/countries.methods';
import VerifLevelMethods from '@/store/verificationLevels/methods/verificationLevels.methods';
/* INTERFACES */
import { User } from '@/modules/auth/interfaces/user.interface';
import { State } from '@/modules/countries/interfaces/states.interface';
import { ReqLevelOne, RequestStatus } from '@/modules/verificationLevels/interfaces/requests.interface';
import { NationalityCountry } from '@/modules/countries/interfaces/countries.interface';
/* FUNCTIONS */
import { showToast } from '@/utils/global-functions';
import { _arrayBufferToBase64 } from '@/utils/image-functions';
import { searchFilter } from '@/utils/filter.functions';
/* CONSTANTS */
import { USER_EMPTY_STATE } from '@/modules/auth/emptyStates/user.emptyState';
import {availableImagesTypes} from '@/utils/constants'
/* LIBRARIES */
import VuePhoneNumberInput from 'vue-phone-number-input';
import codes from 'country-calling-code';
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue'
import InputFile from '@/components/utilities/InputFile.vue';
import Loader from '@/components/utilities/Loader.vue';
import TermsPopup from '@/modules/auth/components/TermsPopup.vue';
import Toast from '@/components/utilities/Toast.vue';
import TermsEuropeContent from '@/modules/auth/components/termsContent/TermsEuropeContent.vue';
import TermsContent from '@/modules/auth/components/termsContent/TermsContent.vue';
@Component({
    components:{
        TermsEuropeContent,
        TermsContent,
        InputFile,
        Loader,
        SvgIcon,
        VuePhoneNumberInput,
        TermsPopup,
        Toast
    },
    mixins: [validationMixin],
    validations:{
        // user: {
        //     main_phone:{required},
        //     ok_legal_terms: { accepted: (val) => val === true },
        // },
        levelOneRequirements:{
            main_phone:{
                value: {required}
            },
            resid_city:{
                value: {required}
            },
            state_name:{
                value: {required}
            },
            pol_exp_per: {
                value:  { accepted: (val) => val === true },
            },
            truthful_information: {
                value:  { accepted: (val) => val === true },
            },
            lawful_funds: {
                value:  { accepted: (val) => val === true },
            },
            ok_legal_terms: {
                value: {required: requiredIf(function(){
                    return this.isUncompletedMigratedUser
                }), accepted: (val) => val === true }
            },
            residCountry: {required: requiredIf(function(){
                    return this.isUncompletedMigratedUser
                })
            },
            domicile_address:{
                value: {required}
            },
            main_sn_platf:{
                value: {}
            },
            user_main_sn_platf:{
                value: {required: requiredIf(function(){
                    return this.levelOneRequirements.main_sn_platf.value && this.levelOneRequirements.main_sn_platf.value !== 'null' && this.levelOneRequirements.main_sn_platf.value !== 'Ninguna'
                })}
            }
        }
    },
    
})
export default class ThirdStep extends Vue {
    @Prop() alreadyRequested?: boolean;
    user: User = USER_EMPTY_STATE;
    $refs : any ={}
    formats = []
    imageAlreadyExists = false;
    stateSearch = ''
    mainTranslations: any = {
        countrySelectorLabel: 'Código país',
        countrySelectorError: 'Elige un país',
        phoneNumberLabel: '',
        example: 'Ejemplo :',
    };
    dropdownCountries: any = [];
    termsModal = false;
    platforms = ['Ninguna','Twitter','Instagram','Facebook']
    mainPhone = ''
    loading = false
    countrySearch = ''

    created(){
        if (this.isUncompletedMigratedUser && this.levelOneRequirements.residCountry)
            this.dropdownCountries[0] = this.levelOneRequirements.residCountry.country_iso_code
        else this.dropdownCountries.push(this.userData.iso_code_resid_country);
        this.user = this.userData;  
        this.formats = availableImagesTypes; 
        // this.levelOneRequirements.main_phone.value = this.user.main_phone
        if (this.user.main_phone) this.mainPhone = this.user.main_phone
    }

    get mainPhoneCountryCode(){
        let phoneCode
        if (this.levelOneRequirements.main_phone_code.value){
            phoneCode = this.levelOneRequirements.main_phone_code.value.replaceAll('+','')
            return codes.find(el => el.countryCodes.includes(phoneCode)).isoCode2
        }
        else if (this.user.main_phone_code){
            phoneCode = this.user.main_phone_code.replaceAll('+','')
            return codes.find(el => el.countryCodes.includes(phoneCode)).isoCode2
        }
        if (this.levelOneRequirements.residCountry)
            return this.levelOneRequirements.residCountry.country_iso_code
        return null
    }

    mounted(){
        this.removeAt();
    }

    removeAt(){
        if (this.levelOneRequirements.user_main_sn_platf.value.length && this.levelOneRequirements.user_main_sn_platf.value[0] === '@'){ 
         this.levelOneRequirements.user_main_sn_platf.value = this.levelOneRequirements.user_main_sn_platf.value.substring(1)}
    }

    get validateUsername(){
        if (this.levelOneRequirements.main_sn_platf.value !== 'Facebook' && this.levelOneRequirements.main_sn_platf.value !== 'Ninguna' && this.levelOneRequirements.user_main_sn_platf.value[0] !=='@'){
            return '@'+this.levelOneRequirements.user_main_sn_platf.value
        }
        return this.levelOneRequirements.user_main_sn_platf.value
    }

    get isEuropeVersion(){
        return process.env.VUE_APP_EUROPE_VERSION
    }

    async submit()
    {
        this.$v.$touch();
        if (!this.$v.$invalid){
            this.loading = true
            this.levelOneRequirements.user_main_sn_platf.value = this.validateUsername;
            let response;
            if (this.levelOneStatus === RequestStatus.BLANK)
                response = await this.verifyIdentUserExistenceExceptThemself({phone_number: this.levelOneRequirements.main_phone_full.value, except: this.userData.email_user})
            if (response && response.error)
                showToast(response.msg,'error')
            else {
                if (this.levelOneStatus !== RequestStatus.BLANK && !this.isUncompletedMigratedUser){
                    if (await this.requestLevelOne(this.levelOneRequirements))
                        this.$router.push({name: 'LevelSuccess'}).catch();
                    else showToast('Ha ocurrido un error solicitando el aumento','error')
                }
                else this.$emit('nextStep')
            }
            this.loading = false
        }  
    }

    /* STATE SEARCH */
    get stateCriteria() {
        return this.stateSearch.trim().toLowerCase();
    }
    get availableStateOptions() {
        const stateCriteria = this.stateCriteria
        if (stateCriteria) {
          return  searchFilter(this.states,['state_name'],this.stateCriteria);
        }
        return this.states
    }
    get searchDesc() {
        if (this.stateCriteria && this.availableStateOptions.length === 0) {
          return 'No se encontraron coincidencias'
        }
        return ''
    }
    /* RESID COUNTRY SEARCH */
    get countryCriteria() {
        return this.countrySearch.trim().toLowerCase();
    }
    get availableCountriesOptions() {
        const countryCriteria = this.countryCriteria
        if (countryCriteria) {
          return  searchFilter(this.nationalityCountries,['spanish_name'],this.countryCriteria);
        }
        return this.nationalityCountries
    }
    get searchCountriesDesc() {
        if (this.stateCriteria && this.availableStateOptions.length === 0) {
          return 'No se encontraron coincidencias'
        }
        return ''
    }

    updateMainPhone(payload: any) {
        if (payload.nationalNumber) {
            let formatNational = payload.nationalNumber;
            if (formatNational && formatNational.charAt(0) === '0') {
                formatNational =formatNational.substring(1);
            } 
            // this.user.main_phone = 
            this.levelOneRequirements.main_phone_full.value = '+'+ payload.countryCallingCode + formatNational;
            this.levelOneRequirements.main_phone.value = formatNational;
            this.levelOneRequirements.main_phone_code.value = '+' + payload.countryCallingCode;
        }
    }

    goBack(){
        this.$emit('prevStep')
    }

    /* MODAL */
    changeTermsModalStatus(newVal: boolean) {
        this.termsModal = newVal;
    }

    /* END MODAL */

    get isUncompletedMigratedUser(){
        // DESCOMENTAR CUANDO SE HAGA EL FLUJO DE MIGRADOS
        // return this.userData.id_migrated && !this.userData.completed_information_migrated
        return false
    }

    get residCountry(){
        if (this.levelOneRequirements.residCountry)
            return this.nationalityCountries.find(el => el.id_all_country === this.levelOneRequirements.residCountry.id_all_country.toString())
        else return null
    }


    /* AUTH */
    @auth.Getter(AuthMethods.getters.GET_USER_DATA)
    userData!: User;
    @auth.Getter(AuthMethods.getters.GET_SELECTED_DOC_TYPE)
    selectedDocType!: number;
    @auth.Getter(AuthMethods.getters.GET_SELECTED_COUNTRY_DOC)
    selectedCountryIdDoc!: string;
    @auth.Action(AuthMethods.actions.VERIFY_IDENT_USER_EXISTENCE)
    verifyIdentUserExistence!: (data: { email_user?: string, phone_number?: string }) => Promise<{ msg?: string, error: boolean }>
    @auth.Action(AuthMethods.actions.VERIFY_IDENT_USER_EXISTENCE_EXCEPT_THEMSELF)
    verifyIdentUserExistenceExceptThemself!: (data:{phone_number:string,except:string}) => Promise<{msg?: string, error: boolean}>
    /* COUNTRIES */
    @countries.Getter(CountryMethods.getters.GET_COUNTRY_STATES)
    states!: State[];
    @countries.Getter(CountryMethods.getters.GET_ALL_COUNTRIES)
    nationalityCountries!: NationalityCountry[]
    /* VERIF LEVELS */
    @verifLevels.Getter(VerifLevelMethods.getters.GET_LEVEL_ONE_REQUEST)
    levelOneRequirements!: ReqLevelOne
    @verifLevels.Action(VerifLevelMethods.actions.REQUEST_LEVEL_ONE)
    requestLevelOne!: (data: ReqLevelOne) => Promise<boolean>;
    @verifLevels.Getter(VerifLevelMethods.getters.GET_LEVEL_ONE_REQUEST_STATUS)
    levelOneStatus!: RequestStatus
}
