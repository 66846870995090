export function searchFilter(list: Array<any>, filters: Array<string>,search: string){
  search = search.replace(/([+(*).{^$}])/g, '\\$1')
  let re = new RegExp(`^.*${search.toLowerCase()}.*$`);
    return list.filter((el)=>{
      return filters.some(filterEl =>{
          let result = el[filterEl];
          // IF OBJECT
          if (filterEl.includes('.')){
            const x = el[filterEl.split('.')[0]];
            const y = Object.assign({},x)[filterEl.split('.')[1]];
            result = y
          }
          // IF ARRAY
          if (result !== null && typeof result === 'object' && result.length > 0){
            let array = result.filter(res=>{
              return filters.some(obj=>{
                let arrayResult = res[obj];
                 if (typeof arrayResult === 'string') arrayResult = arrayResult.toLowerCase()
                 return re.test(arrayResult)
              })
            })
            return array.length > 0
          }
          if (typeof result === 'string') result = result.toLowerCase()
          return re.test(result)
      })
    }
  )
}