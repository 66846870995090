export const BENEFICIARY_RELATIONSHIP=[
'Yo Mismo',
'Familiar',
'Amigo/a',
'Negocio',
'Caridad',
'Otros'
]

export const BENEFICIARY_TYPE={
    NATURAL: 'Natural',
    JURIDICAL: 'Jurídico'
}

export const BENEFICIARY_TYPES=[
    BENEFICIARY_TYPE.NATURAL,
    BENEFICIARY_TYPE.JURIDICAL
]

export const EUROPE_BENEFICIARY_TYPES=[
    BENEFICIARY_TYPE.NATURAL
]