export const FIELDS = {
    BANK : 'Banco',
    ACCOUNT_NUMBER: 'Nro. Cuenta',
    PHONE: 'Telefono',
    EMAIL: 'Correo',
    ACCOUNT_TYPE: 'Tipo Cuenta',
    NOTIFICATION_PHONE: 'Numero telefono',
    BENEFICIARY: 'Beneficiario',
    ID_NUMBER: 'ID',
    IBAN: 'IBAN',
    CI: 'CI'
}