
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
/* VUELIDATE */
import { required, requiredIf } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
/* STORE */
import { auth, countries,docTypes, verifLevels } from '@/store/namespaces';
import AuthMethods from '@/store/auth/methods/auth.methods';
import DocTypesMethods from '@/store/docTypes/methods/docTypes.methods';
import VerifLevelMethods from '@/store/verificationLevels/methods/verificationLevels.methods';
import CountryMethods from '@/store/countries/methods/countries.methods';
/* INTERFACES */
import { User } from '@/modules/auth/interfaces/user.interface';
import {CountryDocType} from '@/modules/docTypes/interfaces/docType.interface';
import { ReqLevelOne, RequestStatus } from '@src/modules/verifLevels/interfaces/requests.interface'
/* CONSTANTS */
import { USER_EMPTY_STATE } from '@/modules/auth/emptyStates/user.emptyState';
import {CALENTAR_LABELS} from '@/utils/bootstrap.constants';
import { GENDERS } from '@/utils/collections';
/* FUNCTIONS */
import { formatDate , validFormatDate, validYearDate, validAdultYearDate } from '@/utils/date-functions';
/* LIBRARIES */
import {getStates} from 'country-state-picker';
// import DatePicker from 'vue2-datepicker';
// import 'vue2-datepicker/index.css';
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import Toast from '@/components/utilities/Toast.vue';

@Component({
    components: {
        SvgIcon,
        Toast,
        // DatePicker
    },
    mixins: [validationMixin],
    validations:{
        levelOneRequirements:{
            date_birth:{
                value: {required,validFormatDate, validYearDate, validAdultYearDate}
            },
            gender: { 
                value: { required }
            },
            occupation: {
                value: {required}
            },
            // main_sn_platf:{
            //     value: {}
            // },
            // user_main_sn_platf:{
            //     value: {required: requiredIf(function(){
            //         return this.levelOneRequirements.main_sn_platf.value !== null && this.levelOneRequirements.main_sn_platf.value !== 'null' && this.levelOneRequirements.main_sn_platf.value !== 'Ninguna'
            //     })}
            // }
        }
    }
})
export default class FirstStep extends Vue {
    @Prop() alreadyRequested?: boolean;
    user: User = USER_EMPTY_STATE;
    date = '';
    loader = false;
    calendarLabels= CALENTAR_LABELS;
    genders = GENDERS;

    async mounted(){
        this.user =  this.userData;
       // this.getIsoCode();
        // if (this.levelOneRequirements.main_sn_platf.value.length === 0 || this.levelOneRequirements.main_sn_platf.value === null)
        //  this.levelOneRequirements.main_sn_platf.value = this.platforms[0]
        // let residCountry = this.levelOneRequirements.id_resid_country.value;
        // let residCountry = this.user.id_resid_country
        // if (typeof residCountry === 'string') residCountry = parseInt(residCountry);
        // this.politicallyExponseQuestion = await this.politicallyExponse(residCountry);
    }

    get currentGender(){
        return this.genders.find(el => el.key === this.levelOneRequirements.gender.value)
    }

    get isUncompletedMigratedUser(){
        // DESCOMENTAR CUANDO SE HAGA EL FLUJO DE MIGRADOS
        // return this.userData.id_migrated && !this.userData.completed_information_migrated
        return false
    }


    // async getIsoCode(){
    //     let isoCode : string | boolean = await this.getCountryCode(this.user.id_resid_country);
    //     if (typeof isoCode !== 'boolean'){
    //         this.states = getStates(isoCode);
    //     }    
    // }

    @Watch('date')
    changeDateFormat(val: string){
        this.levelOneRequirements.date_birth.value = formatDate(val);
    }
    

    submitForm(){
        this.$v.$touch();
        if (!this.$v.$invalid){
                this.$emit('nextStep');            
        }
    }


    /* AUTH */
    @auth.Getter(AuthMethods.getters.GET_USER_DATA)
    userData!: User;
    /* DOC TYPES */
    @docTypes.Getter(DocTypesMethods.getters.GET_MAIN_DOC_TYPES)
    mainDocTyoes!: CountryDocType[];
    @docTypes.Getter(DocTypesMethods.getters.GET_SECONDARY_DOC_TYPES)
    secondaryDocTyoes!: CountryDocType[];
    /* COUNTRIES */
    @countries.Action(CountryMethods.actions.GET_ISO_CODE)
    getCountryCode!: (countryId: number | string) => Promise<string | boolean>
    @countries.Action(CountryMethods.actions.POLITICALLY_EXPOSE_QUESTION)
    politicallyExponse!: (countryId : number) => Promise<boolean>
    /* VERIF LEVELS */
    @verifLevels.Getter(VerifLevelMethods.getters.GET_LEVEL_ONE_REQUEST)
    levelOneRequirements!: ReqLevelOne
}
