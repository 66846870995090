
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { required, requiredIf,email} from 'vuelidate/lib/validators';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import { FrequentBeneficiary } from '../../interfaces/beneficiaries.interfaces';
import {banks,payMethods} from '@/store/namespaces';
/* STORE METHODS */
import BanksMethods from '@/store/banks/methods/banks.methods';
import PayMethodsMethods from '@/store/payMethods/methods/payMethods.methods'
/* INTERFACES */
import {CountryDocType} from '@/modules/docTypes/interfaces/docType.interface';
import { CountryCurrency,NationalityCountry } from '@/modules/countries/interfaces/countries.interface';
import { PayMethod } from '@/modules/payMethods/interfaces/payMethods.interface';
import { Bank } from '@/modules/banks/interfaces/bank.interface';
/* CONSTANTS */
import {FIELDS} from '@/modules/payMethods/constants/fields.constant'
import {ACCOUNT_TYPES_ARRAY,CHILEAN_ACCOUNT_TYPES_ARRAY} from '@/modules/accounts/contants/account.constants'
/* LIBRARIES */
import VuePhoneNumberInput from 'vue-phone-number-input';
import { showToast } from '@/utils/global-functions';
import { Field } from '@/modules/payMethods/interfaces/fields.interface';

@Component({
    components: {
        SvgIcon,
        VuePhoneNumberInput
    },
    mixins: [validationMixin],
    validations:{
        extraData:{
            selectedBank:{name:{
                required: requiredIf(function(){
                        // @ts-ignore
                        if (this.extraData.selectedPayMethod.idPayMethod && this.extraData.selectedPayMethod.fields.some(el=> el.name_field === FIELDS.BANK))
                            return true
                    })
            }},
            selectedPayMethod:{idPayMethod:{required}},
        },
        beneficiary:{
            
            account:{
                required: requiredIf(function(){
                    // @ts-ignore
                    if (this.extraData.selectedPayMethod.idPayMethod && (this.extraData.selectedPayMethod.fields.some(el=> el.name_field === FIELDS.ACCOUNT_NUMBER) || this.extraData.selectedPayMethod.fields.some(el=> el.name_field === FIELDS.IBAN)))
                        return true
                }),
                containsIdentCode: function (value) {
                    if (value !== null  && 
                            this.isVECountry &&
                        this.extraData.selectedPayMethod.idPayMethod && 
                        this.extraData.selectedPayMethod.fields.some(el=> el.name_field === FIELDS.ACCOUNT_NUMBER) && 
                        this.extraData.selectedBank.ident_code !== null)
                      return value.startsWith(this.extraData.selectedBank.ident_code);
                    else return true
                },
                isVenezuelanAccount: function (value) {
                    if (value !== null && this.extraData.selectedPayMethod.idPayMethod && this.extraData.selectedPayMethod.fields.some(el=> el.name_field === FIELDS.ACCOUNT_NUMBER) && this.isVECountry)
                      return value.length  === 20
                    else return true
                },
            },
            account_type:{
                required: requiredIf(function(){
                    // @ts-ignore
                    if (this.extraData.selectedPayMethod.idPayMethod && this.extraData.selectedPayMethod.fields.some(el=> el.name_field === FIELDS.ACCOUNT_TYPE))
                        return true
            })},
            phone_number:{
                required: requiredIf(function(){
                    // @ts-ignore
                    if (this.extraData.selectedPayMethod.idPayMethod && (this.extraData.selectedPayMethod.fields.some(el=> { if (el.name_field === FIELDS.PHONE && el.is_optional === false) return el}) || this.beneficiary.name_optional_field === FIELDS.PHONE))
                        return true
            })},
            email:{email,
                required: requiredIf(function(){
                    // @ts-ignore
                    if (this.extraData.selectedPayMethod.idPayMethod && (this.extraData.selectedPayMethod.fields.some(el=> { if (el.name_field === FIELDS.EMAIL && el.is_optional === false) return el}) || this.beneficiary.name_optional_field === FIELDS.EMAIL))
                        return true
            })},
        },
        phoneNumber:{
            required: requiredIf(function(){
                    // @ts-ignore
                    if (this.extraData.selectedPayMethod.idPayMethod && (this.extraData.selectedPayMethod.fields.some(el=> { if (el.name_field === FIELDS.PHONE && el.is_optional === false) return el}) || this.beneficiary.name_optional_field === FIELDS.PHONE))
                        return true
            })
        }
    }
})
export default class ThirdStep extends Vue {
    @Prop() beneficiary!: FrequentBeneficiary;
    @Prop() extraData!: {selectedCountryCurrency: CountryCurrency,selectedPayMethod:PayMethod,selectedBank:Bank,selectedDocCountry:NationalityCountry,selectedDocType: CountryDocType,frequent: boolean,selectedVenezuelanCIType?: string};
    @Prop() type?: string;
    payMethods: PayMethod[] = [];
    loader = false;
    fields = FIELDS;
    accountTypes: string[]= [];
    banks: Bank[] = [];
    mainTranslations: any = {
        countrySelectorLabel: 'Código país',
        countrySelectorError: 'Elige un país',
        phoneNumberLabel: '',
        example: 'Ejemplo :',
    };
    dropdownCountries: any = ['VE'];
    phoneNumber = '';

    async mounted(){
        if (this.type === 'edit'){
            this.banks = await this.fetchBanks({pay_method_id: this.extraData.selectedPayMethod.idPayMethod,currency_id: this.extraData.selectedCountryCurrency.id_currency,country_id:this.extraData.selectedCountryCurrency.id_country,origin: true})
            this.extraData.selectedBank = this.banks.find(el=> el.idBank === this.extraData.selectedBank.idBank)
        }
        if (this.beneficiary.phone_number_full) this.phoneNumber = this.beneficiary.phone_number_full.phoneNumber
        this.payMethods =  await this.fetchPayMethods({id_country: this.extraData.selectedCountryCurrency.id_country,id_currency: this.extraData.selectedCountryCurrency.id_currency,only_pay: true});
    
        if (this.extraData.selectedCountryCurrency.viewing_name === 'Chile'){
            this.accountTypes = CHILEAN_ACCOUNT_TYPES_ARRAY;
        } else {
            this.accountTypes = ACCOUNT_TYPES_ARRAY;
        }
    }

    async selectPayMethod(payMethod: PayMethod){
        this.loader = true;
        this.refreshAccountData();
        this.extraData.selectedPayMethod = payMethod;
        this.banks = await this.fetchBanks({pay_method_id: this.extraData.selectedPayMethod.idPayMethod,currency_id: this.extraData.selectedCountryCurrency.id_currency,country_id:this.extraData.selectedCountryCurrency.id_country,origin: true})
        if (this.banks === undefined){
            showToast('Ha ocurrido un error. Intente de nuevo','error');
            this.$emit('closeModal')
        }
        this.loader = false;
        // if (this.extraData.selectedPayMethod.fields.length === 0){
        //     this.$emit('removeLastStep')
        // }
    }

    refreshAccountData(){
        this.extraData.selectedBank = {name: null, idBank:null, id_bank:null, ident_code: null}
        this.beneficiary.account = null; 
        this.beneficiary.account_type = null;
        this.beneficiary.email = null;
        this.beneficiary.id_bank = null;
        this.beneficiary.id_optional_field = null;
        this.beneficiary.name_optional_field = null;
        this.beneficiary.name_bank = null
        this.beneficiary.phone_number = null;
    }



    validate(){
        this.$v.$touch();
         return this.$v.$invalid          
    }

    selectOptionalField(field: Field){
        this.beneficiary.account = null; 
        this.beneficiary.email = null;
        this.beneficiary.phone_number = null;
        this.beneficiary.id_optional_field = field.id_field; 
        this.beneficiary.name_optional_field = field.name_field;
        this.$forceUpdate();
    }

    /* PHONE */
    updateMainPhone(payload: any) {
        this.beneficiary.phone_number = payload.formattedNumber;
        this.beneficiary.phone_number_full = payload;
    }

    get isVECountry(){
        return this.extraData.selectedCountryCurrency.country_iso_code === 'VE'
    }

    get optionalFields(){
        return this.extraData.selectedPayMethod.fields.filter(el=> el.is_optional === true)
    }


    /* BANKS */
    @banks.Action(BanksMethods.actions.FETCH_BANKS)
    fetchBanks!: (params: any) => Promise<Bank[] | undefined>

    /* PAY_METHODS */
    @payMethods.Action(PayMethodsMethods.actions.FETCH_PAY_METHODS_BY_COUNTRY)
    fetchPayMethods!: (data: any) => Promise<PayMethod[] | undefined>;

}
