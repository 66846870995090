
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
/* VUELIDATE */
import { validationMixin } from 'vuelidate';
import { required} from 'vuelidate/lib/validators';
/* STORE */
import {countries,currencies,payMethods,docTypes,beneficiaries} from '@/store/namespaces';
import CountriesMethods from '@/store/countries/methods/countries.methods';
import CurrenciesMethods from '@/store/currencies/methods/currencies.methods';
import PayMethodsMethods from '@/store/payMethods/methods/payMethods.methods';
import DocTypesMethods from '@/store/docTypes/methods/docTypes.methods';
import BeneficiariesMethods from '@/store/beneficiaries/methods/beneficiaries.methods';
/* INTERFACES */
import { FrequentBeneficiary } from '../../interfaces/beneficiaries.interfaces';
import {CountryDocType} from '@/modules/docTypes/interfaces/docType.interface';
import { Country, CountryCurrency, NationalityCountry } from '@/modules/countries/interfaces/countries.interface';
import { Currency } from '@/modules/currencies/interfaces/currencies.interface';
import { PayMethod } from '@/modules/payMethods/interfaces/payMethods.interface';
import { Bank } from '@/modules/banks/interfaces/bank.interface';
/* CONSTANTS */
import {BENEFICIARY_TYPES,BENEFICIARY_TYPE,EUROPE_BENEFICIARY_TYPES} from '@/modules/beneficiaries/constants/beneficiaries.constants';
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue';
@Component({
    components: {
        SvgIcon,
    },
    mixins: [validationMixin],
    validations:{
        extraData:{
            selectedCountryCurrency:{id_country:{required}},
            // selectedCurrency:{isoCode:{required}},
        },
        beneficiary:{
            beneficiaryType:{required}
        }
    }
})
export default class FirstStep extends Vue {
    @Prop() beneficiary!: FrequentBeneficiary;
    @Prop() extraData!: {selectedCountryCurrency: CountryCurrency,selectedPayMethod:PayMethod,selectedBank:Bank,selectedDocCountry:NationalityCountry,selectedDocType: CountryDocType,selectedVenezuelanCIType?: string};
    @Prop() payMethods!: PayMethod[];
    @Prop() type!: string;
    // selectedCountry: Country = {id_country: '', name_country: '', country_iso_code :''}
    // selectedCurrency: Currency = {idCourrency: -1, name: '', isoCode:'',type: ''}
    currencies : CountryCurrency[] = [];
    loader = false;
    beneficiaryTypes: BENEFICIARY_TYPES[] = [];   

    async mounted(){
        let countryCurrency = await this.extraData.selectedCountryCurrency;
        if (!this.beneficiary.beneficiaryType) {
            this.beneficiary.beneficiaryType = BENEFICIARY_TYPE.NATURAL;
            if (this.type === 'addToList'){ 
                await this.fetchDocTypes({id_country: countryCurrency.id_country, person_type: this.beneficiary.beneficiaryType, signup_or_payment: 'payment'});
            }
        }
        if (countryCurrency.id_country !== null){
            // this.currencies = await this.fetchCurrencies({country_id: countryCurrency.id_country,origin: false})
            let payMethods =  await this.fetchPayMethods({id_country: countryCurrency.id_country,id_currency: this.extraData.selectedCountryCurrency.id_currency,only_pay: true});
            this.$emit('selectPayMethodsFromChild',payMethods)
        }
        this.listBeneficiaryTypes()
        this.$forceUpdate()
        // this.loader = false;
    }

    showLoader(){this.loader = true}
    hideLoader(){this.loader = false}

    @Watch('extraData.selectedCountryCurrency.id_country')
    async selectedCountry(){
       let payMethods =  await this.fetchPayMethods({id_country: this.extraData.selectedCountryCurrency.id_country,id_currency: this.extraData.selectedCountryCurrency.id_currency});
       this.$emit('selectPayMethodsFromChild',payMethods)
    }

    async selectCountry(country: CountryCurrency){
       this.extraData.selectedCountryCurrency = country;
       this.loader =true
       try{
            let payMethods = []
            this.$emit('selectPayMethodsFromChild',payMethods)
            payMethods = await this.fetchPayMethods({id_country: country.id_country, id_currency: country.id_currency});
            await this.fetchDocTypes({id_country: country.id_country, person_type: this.beneficiary.beneficiaryType, signup_or_payment: 'payment'});
            this.beneficiary.contactRequired = (await this.fetchContactRequired(country.id_country)).requiredContact;
            await this.$emit('selectPayMethodsFromChild',payMethods)
            this.extraData.selectedPayMethod=  {idPayMethod: null, name: null, fields:[]}
            this.extraData.selectedBank = {idBank:-1,name:''};
            this.extraData.selectedDocType =  null;
            this.beneficiary.id_pay_method = null;
            this.beneficiary.id_bank = null;
            this.beneficiary.email = null;
            this.beneficiary.phone_number = null;
            this.beneficiary.account = null;
            this.beneficiary.account_type = null;
            this.currencies = this.destinyCountriesCurrencies.filter(el=> el.id_country === country.id_country)
       }
       catch(e){
            showToast('Ha ocurrido un error. Intente de nuevo','error')
       }
       this.loader = false
       this.listBeneficiaryTypes()
    }

    listBeneficiaryTypes(){
        if (this.extraData.selectedCountryCurrency.viewing_name === 'España'){
            this.beneficiaryTypes= EUROPE_BENEFICIARY_TYPES;
        } else {
            this.beneficiaryTypes= BENEFICIARY_TYPES;
        }
    }

    async selectBeneficiaryType(beneficiaryType: string){
        this.loader = true;
        this.beneficiary.beneficiaryType = beneficiaryType; 
        this.$forceUpdate();
        await this.fetchDocTypes({id_country: this.extraData.selectedCountryCurrency.id_country, person_type: beneficiaryType, signup_or_payment: 'payment'});
        this.loader = false;
    }

    validate(){
        this.$v.$touch()
        return this.$v.$invalid          
    }

    /* COUNTRIES */
    @countries.Getter(CountriesMethods.getters.GET_DESTINY_COUNTRY_CURRENCY)
    destinyCountriesCurrencies!: CountryCurrency[];
    /* CURRENCIES */
    @currencies.Action(CurrenciesMethods.actions.FETCH_CURRENCIES_BY_COUNTRIES)
    fetchCurrencies!: (params: any) => Promise<Currency[]>
    /* PAY METHODS */
    @payMethods.Action(PayMethodsMethods.actions.FETCH_PAY_METHODS_BY_COUNTRY)
    fetchPayMethods!:(data: any) => Promise<PayMethod[] | undefined>
    /* DOC TYPES */
    @docTypes.Action(DocTypesMethods.actions.FETCH_DOC_TYPES)
    fetchDocTypes!: (params: {id_country: number, person_type: string,signup_or_payment: string}) => Promise<boolean>;
    /* BENEFICIARIES */
    @beneficiaries.Action(BeneficiariesMethods.actions.FETCH_BENEFICIARY_CONTACT_REQUIRED)
    fetchContactRequired!: (countryId: number) => Promise<{requiredContact : boolean}>
}
