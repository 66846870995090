
import { Component, Vue, Watch, Prop} from 'vue-property-decorator';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import { showToast } from '@/utils/global-functions';
 
@Component({
    components: {
        SvgIcon,
    },
})
export default class InputFile extends Vue {
    @Prop() formats? : Array<string>;
    @Prop() existImage? : File;
    @Prop() imageSource?: boolean;
    @Prop() small?: boolean;
    @Prop() type?: number;
    @Prop() vertical?: boolean;
    image = null;
    imageSrc = null
    progress = {
        width: '0%',
    };
    imageType = false;
    showDoc = false;

    mounted(){
      if (this.existImage){
        this.image = this.existImage;
      }
    }
    refreshImage(image: File){
        if (image){
          this.image = image;
        }
    }

    get hasImage() {
      return !!this.image;
    }

    @Watch('existImage')
    watchExistingImage(image: File){
      this.image = image
    }

    @Watch('image')
    watchImage(newValue, oldValue) {
      console.log('watchImage', newValue, oldValue)
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then(value => {
              this.imageSrc = value;
              if (this.imageSource) this.$emit('getImageSource',this.imageSrc)
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    }

    base64Encode = data => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onprogress = this.updateProgress;
        reader.onload = () => {
            this.progress.width = '100%'
            resolve(reader.result)
            this.imageType = this.isImage(reader.result)
            this.docReady()
        };
        reader.onerror = error => reject(error);
    });

    isImage(content: string | ArrayBuffer){
        if (typeof content !== 'string') content = content.toString();
        if (content.includes('data:image/')) return true
        return false
    }

    updateProgress(evt: ProgressEvent) {
        if (evt.lengthComputable) {
        var percentLoaded = Math.round((evt.loaded / evt.total) * 100);
        // Increase the progress bar length.
        if (percentLoaded < 100) {
            this.progress.width = percentLoaded + '%';
        }
        }
    }

    get formatString(){
      let result = ''
      this.formats.forEach(el =>{
          result += el+', '
      })
      return result
    }

    clearImage() {
      this.image = null;
    }

    docReady() {
      console.log('DOCREADY image type', typeof this.image)
      let maxSixe = 10000000; //10MB
      if (this.image.size > maxSixe){
        showToast('La imagen debe ser menor a 10MB','error')
        this.image = null;
      }
      else if (!this.formats.includes(this.image.type)){
        showToast('Formato de adjunto no permitido','error')
        this.image = null;
      }
      else  this.$emit('getImage', this.image)
    }
}
