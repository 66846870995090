
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
/* STORE */
import {
    banks,
    countries,
    currencies,
    remittance,
    auth,
    docTypes,
    payMethods,
    rates,
} from '@/store/namespaces';
import BankMethods from '@/store/banks/methods/banks.methods';
import CountriesMethods from '@/store/countries/methods/countries.methods';
import CurrenciesMethods from '@/store/currencies/methods/currencies.methods';
import RemittanceMethods from '@/store/remittance/methods/remittance.methods';
import AuthMethods from '@/store/auth/methods/auth.methods';
import DocTypesMethods from '@/store/docTypes/methods/docTypes.methods';
import PayMethodsMethods from '@/store/payMethods/methods/payMethods.methods';
import RatesMethods from '@/store/rates/methods/rates.methods';
/* CONSTANTS */
import { availableImagesTypes } from '@/utils/constants';
/* INTERFACES */
import {
    Capture,
    NewRemittance,
} from '../../interfaces/newRemittance.interface';
import { PayMethod } from '@/modules/payMethods/interfaces/payMethods.interface';
import { CountryDocType } from '@/modules/docTypes/interfaces/docType.interface';
import { User } from '@/modules/auth/interfaces/user.interface';
import { Currency } from '@/modules/currencies/interfaces/currencies.interface';
import { Country } from '@/modules/countries/interfaces/countries.interface';
/* COMPONENTS */
import InputFile from '@/components/utilities/InputFile.vue';
import ConfirmationModal from '@/components/utilities/ConfirmationModal.vue';
import InfoModal from '@/components/utilities/InfoModal.vue';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
/* FUNCTIONS */
import { showToast } from '@/utils/global-functions';
/* VUELIDATE */
import {
    required,
    minValue,
    maxValue,
    requiredIf,
    minLength,
    maxLength,
} from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
/* DIRECTIVES */
import { VMoney } from 'v-money';
import { Bank } from '@/modules/banks/interfaces/bank.interface';
import { MaxAmmount } from '../../interfaces/limits.interface';
import { REMITTANCE_TYPES } from '../../constants/remittance.constants';
import { BankAccount } from '@/modules/banks/interfaces/bankAccounts.interface';
import { PreRemittance } from '../../interfaces/preRemittance.interface';

@Component({
    components: {
        InputFile,
        ConfirmationModal,
        InfoModal,
        SvgIcon,
    },
    mixins: [validationMixin],
    validations: {
        firstCapture: {
            // ammount:{
            //     required,
            //     minValue: minValue(1),
            // },
            ref: { required, minLength: minLength(6), maxLength: maxLength(6) },
        },
        secondCapture: {
            ammount: {
                minLengthIfRequired: function (value) {
                    if (this.secondCaptureActive && value < 1) return false;
                    else return true;
                },
            },
            ref: {
                required: requiredIf(function () {
                    if (this.secondCaptureActive) return true;
                }),
                minLength: minLength(6),
                maxLength: maxLength(6),
            },
            // content:{required: requiredIf(function(){
            //     if (this.secondCaptureActive)
            //         return true
            // })}
        },
        newRemittance: {
            thirdPartyTransfer: {
                country: {
                    idCountry: {
                        required: requiredIf(function () {
                            if (this.newRemittance.thirdPartyTransfer.response)
                                return true;
                        }),
                    },
                },
                bank: {
                    idBank: {
                        required: requiredIf(function () {
                            if (this.newRemittance.thirdPartyTransfer.response)
                                return true;
                        }),
                    },
                },
                currency: {
                    idCourrency: {
                        required: requiredIf(function () {
                            if (this.newRemittance.thirdPartyTransfer.response)
                                return true;
                        }),
                    },
                },
                docType: {
                    id_ident_doc_type: {
                        required: requiredIf(function () {
                            if (this.newRemittance.thirdPartyTransfer.response)
                                return true;
                        }),
                    },
                },
                holder: {
                    required: requiredIf(function () {
                        if (this.newRemittance.thirdPartyTransfer.response)
                            return true;
                    }),
                },
                id: {
                    required: requiredIf(function () {
                        if (this.newRemittance.thirdPartyTransfer.response)
                            return true;
                    }),
                },
                accountNumber: {
                    required: requiredIf(function () {
                        if (this.newRemittance.thirdPartyTransfer.response)
                            return true;
                    }),
                },
            },
        },
    },
    directives: {
        money: VMoney,
    },
})
export default class PaymentProof extends Vue {
    proof = null;
    formats: Array<string> = [];
    ref: number = 0;
    ammount: number = 0;
    formattedCurrency = {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: true,
    };
    secondCaptureActive = false;
    addThirdPartyAccountModal = false;
    deleteConfirmationModal = false;
    infoModal = {
        open: false,
        title: '',
    };
    thirtdPartyAdded = false;
    firstCapture: Capture = {
        messageId: null,
        ammount: 0,
        formattedAmmount: '0',
        ref: '',
        date: '',
        content: null,
    };
    secondCapture: Capture = {
        messageId: null,
        ammount: 0,
        formattedAmmount: '0',
        ref: '',
        date: '',
        content: null,
    };
    currencies: Currency[] = [];
    //  payMethods: PayMethod[] = []
    selectedCountryDocTypes: CountryDocType[] = [];
    banks: Bank[] = [];
    loader = false;
    $refs: any = {};
    showFirstCapture = true;

    async mounted() {
        this.$emit('loaderOn');
        this.formats = availableImagesTypes;
        this.newRemittance.thirdPartyTransfer.country =
            this.newRemittance.originCountry;
        this.newRemittance.thirdPartyTransfer.currency =
            this.newRemittance.countryCurrency;
        this.newRemittance.thirdPartyTransfer.bank = this.newRemittance.bank;
        this.selectCountry(this.newRemittance.originCountry);
        if (this.newRemittance.captures[0]) {
            this.firstCapture = this.newRemittance.captures[0];
            this.$refs.inputFile1.refreshImage(this.firstCapture.content);
        }
        if (this.newRemittance.captures[1]) {
            this.secondCaptureActive = true;
            this.secondCapture = this.newRemittance.captures[1];
            this.$forceUpdate();
            if (this.$refs.inputFile2)
                await this.$refs.inputFile2.refreshImage(
                    this.secondCapture.content
                );
        }
        if (!this.alreadyHasPaymentProof || this.preRemittanceActive)
            await this.fetchBankAccount(
                this.newRemittance.holder.idBankAccount
            );
        if (this.newRemittance.thirdPartyTransfer.response)
            this.thirtdPartyAdded = true;
        // if (this.newRemittance.depositMethod.name === 'Webpay') this.newRemittance.captures.push
        this.$emit('loaderOff');
        console.log('this.$router.currentRoute.query.token_ws.toString(): ',this.$router.currentRoute.query.token_ws.toString())

        if (this.$router.currentRoute.query.token_ws) {
            this.confirmAndSaveRemittance();
        }
    }

    /* COMPUTED */
    get continueTitleText() {
        let titleText = 'Continuar';
        if (this.newRemittance.depositMethod.name === 'Webpay')
            titleText = 'Usted está pagando con';
        else titleText = 'Orden de Pago';
        return titleText;
    }

    get alreadyHasPaymentProof() {
        if (this.$route.params.modo === REMITTANCE_TYPES.WITH_CAPTURE)
            return true;
        else return false;
    }

    get preRemittanceActive() {
        if (
            this.preRemittance.active === true &&
            this.preRemittance.was_expired === null
        )
            return true;
        else return false;
    }

    /* MODAL */
    deleteConfirmationModalStatus(newVal: boolean) {
        this.deleteConfirmationModal = newVal;
    }
    informationModalStatus(newVal: boolean) {
        this.infoModal.open = newVal;
    }
    /* END MODAL */

    async getFirstCapture(image: File) {
        this.firstCapture.content = image; //await image.arrayBuffer();
    }

    async getSecondCapture(image: File) {
        this.secondCapture.content = image; //await image.arrayBuffer();
    }

    async getCapture(image: File) {
        console.log('IMAGE', image);
        console.log('IMAGE type', typeof image);
        if (this.showFirstCapture) this.firstCapture.content = image;
        else this.secondCapture.content = image;
        console.log('this.firstCapture: ', this.firstCapture);

        // const reader = new FileReader();

        // reader.onload = (event) => {
        //     console.log('ONLOAD',event.target.result);
        // };

        // reader.onerror = (error) => {
        //     console.error('Error reading file:', error);
        // };

        // reader.readAsText(new Blob(['../../../../assets/paymentMethods/webpayplus.png'], { type: 'image/png' }));

        /////////////////////////////

        // fs.readFile('../../../../assets/paymentMethods/webpayplus.png', (err, data) => {
        //     if (err) {
        //         console.error('Error reading image file:', err);
        //         return;
        //     }

        //     const fileObject = new File([data], 'webpayplus.png', { type: 'image/png' });
        //     console.log('IMAGEN WEBPAY CONVERTIDA A FILE',fileObject); // This is the File object
        // });
    }

    get image() {
        if (this.showFirstCapture) return this.firstCapture.content;
        else return this.secondCapture.content;
    }

    async selectCountry(country: any) {
        this.newRemittance.thirdPartyTransfer.country = country;
        this.selectedCountryDocTypes = this.docTypes.filter(
            (el) => el.name_country === country.name
        );
        this.currencies = await this.fetchCurrencies({
            country_id: parseInt(country.idCountry),
            origin: false,
        });
    }

    deleteCapture() {
        this.secondCaptureActive = false;
        this.showFirstCapture = true;
        this.secondCapture = {
            messageId: null,
            ammount: 0,
            formattedAmmount: '0',
            ref: '',
            date: '',
            content: null,
        };
    }

    async selectCurrency(currency: Currency) {
        this.newRemittance.thirdPartyTransfer.currency = currency;
        this.banks = await this.fetchBanks({
            pay_method_id: null,
            currency_id:
                this.newRemittance.thirdPartyTransfer.currency.idCourrency,
            country_id:
                this.newRemittance.thirdPartyTransfer.country.id_country,
            origin: true,
        });
    }

    changeInputFileView() {
        this.showFirstCapture = !this.showFirstCapture;
    }

    @Watch('firstCapture.formattedAmmount')
    convertFirstCaptureAmmountToNumber(newVal: string) {
        this.firstCapture.ammount = parseFloat(
            newVal.replaceAll('.', '').replace(',', '.')
        );
    }

    @Watch('secondCapture.formattedAmmount')
    convertSecondCaptureAmmountToNumber(newVal: string) {
        this.secondCapture.ammount = parseFloat(
            newVal.replaceAll('.', '').replace(',', '.')
        );
    }

    get totalOriginCurrency() {
        return this.$options.filters.currency(
            this.newRemittance.totalDeposited
        );
    }

    nextStep() {
        this.$v.$touch();
        this.newRemittance.captures = [];
        if (
            !this.firstCapture.content ||
            (this.secondCaptureActive && !this.secondCapture.content)
        ) {
            showToast('Debe anexar los comprobantes de pago', 'error');
        }
        // else if( (!this.alreadyHasPaymentProof && (this.firstCapture.ammount + this.secondCapture.ammount) !== this.newRemittance.totalDeposited)){
        //         showToast('Monto total incorrecto','error')
        // }
        else if (!this.$v.$invalid) {
            console.log('EN EL PUSH this.firstCapture: ', this.firstCapture);
            this.newRemittance.captures.push(this.firstCapture);
            if (this.secondCaptureActive)
                this.newRemittance.captures.push(this.secondCapture);
            // if (this.$route.params.modo === REMITTANCE_TYPES.WITH_CAPTURE)
            //    this.newRemittance.totalDeposited = this.newRemittance.captures.map(item => item.ammount).reduce((prev, curr) => prev + curr, 0);
            this.$emit('nextStep');
        }
        console.log(
            'this.newRemittance.captures: ',
            this.newRemittance.captures
        );
    }

    addThirdPartyAccount() {
        this.$v.newRemittance.thirdPartyTransfer.$touch();
        this.newRemittance.thirdPartyTransfer.response = true;
        if (!this.$v.newRemittance.thirdPartyTransfer.$invalid) {
            this.addThirdPartyAccountModal = false;
            this.thirtdPartyAdded = true;
        }
    }

    deleteThirdPartyData() {
        this.deleteConfirmationModal = true;
    }

    deleteConfirmed() {
        this.deleteConfirmationModal = false;
        this.thirtdPartyAdded = false;
        this.newRemittance.thirdPartyTransfer.response = false;
        this.newRemittance.thirdPartyTransfer.docType = {
            id_ident_doc_type: null,
            name_doc_type: '',
            id_resid_country: 0,
            type_doc_type: '',
            name_country: '',
        };
        this.newRemittance.thirdPartyTransfer.holder = '';
        this.newRemittance.thirdPartyTransfer.id = '';
        this.newRemittance.thirdPartyTransfer.accountNumber = '';
        this.newRemittance.thirdPartyTransfer.pathNameDoc = null;
        this.newRemittance.thirdPartyTransfer.nameBankDoc = null;
    }

    goToChat() {
        this.$router.push({ name: 'Chat' }).catch();
        this.infoModal.open = false;
    }

    async confirmAndSaveRemittance() {
        this.$emit('loaderOn');
        let response = await this.sendWebpayRemittance({
            remittance: this.newRemittance,
            token: this.$router.currentRoute.query.token_ws.toString(),
        });
        console.log('response: ', response);
        if (response.error) {
            showToast('Ha ocurrido un error al crear su operación', 'error');
        } else {
            this.$router.push({ name: 'SendPreRemittanceSuccess' }).catch();
            this.$emit('nextStep');
        }
        this.$emit('loaderOff');
    }

    /* RATES */
    @rates.Getter(RatesMethods.getters.GET_MIN_AMMOUNT_IN_REMITTANCE)
    minAmmountInRemittance!: { value: number; currency: string };
    /* COUNTRIES */
    @countries.Getter(CountriesMethods.getters.GET_DESTINY_COUNTRIES)
    destinyCountries!: Country[];
    /* CURRENCIES */
    @currencies.Action(CurrenciesMethods.actions.FETCH_CURRENCIES_BY_COUNTRIES)
    fetchCurrencies!: (params: {
        country_id: number;
        origin: boolean;
    }) => Promise<Currency[]>;
    /* REMITTANCE */
    @remittance.Getter(RemittanceMethods.getters.GET_NEW_REMITTANCE)
    newRemittance!: NewRemittance;
    @remittance.Getter(RemittanceMethods.getters.GET_MAX_AMMOUNTS)
    maxAmmounts!: MaxAmmount;
    @remittance.Getter(RemittanceMethods.getters.GET_WEEKLY_ACUM)
    weeklyAcum!: number;
    @remittance.Getter(RemittanceMethods.getters.GET_MONTHLY_ACUM)
    monthlyAcum!: number;
    @remittance.Getter(RemittanceMethods.getters.GET_TOTAL_ACUM)
    totalAcum!: number;
    @remittance.Getter(RemittanceMethods.getters.GET_VALID_THIRD_NAT_TRANSF)
    validThirdTransf!: boolean;
    @remittance.Getter(RemittanceMethods.getters.GET_VALID_THIRD_EXT_TRANSF)
    validThirdExtTransf!: boolean;
    @remittance.Getter(RemittanceMethods.getters.GET_RESID_COUNTRY_CURRENCY)
    residCountryCurrency!: string;
    @remittance.Getter(RemittanceMethods.getters.GET_PRE_REMITTANCE)
    preRemittance!: PreRemittance;
    @remittance.Action(RemittanceMethods.actions.SEND_WEBPAY_REMITTANCE)
    sendWebpayRemittance!: (payload: {
        remittance: NewRemittance;
        token: string;
    }) => Promise<{ error: boolean; remittancePubID?: string }>;
    /* AUTH */
    @auth.Getter(AuthMethods.getters.GET_USER_DATA)
    userData!: User;
    /* DOC TYPES */
    @docTypes.Getter(DocTypesMethods.getters.GET_DOC_TYPES)
    docTypes!: CountryDocType[];
    /* BANKS*/
    @banks.Action(BankMethods.actions.FETCH_BANKS)
    fetchBanks!: (params: {
        pay_method_id: number;
        currency_id: number;
        country_id: string;
        origin: boolean;
        save?: boolean;
    }) => Promise<Bank[] | undefined>;
    @banks.Action(BankMethods.actions.FETCH_BANK_ACCOUNT)
    fetchBankAccount!: (bankAccountId: number) => Promise<boolean>;
    @banks.Getter(BankMethods.getters.GET_BANK_ACCOUNT)
    bankAccount!: BankAccount;
}
