export function _arrayBufferToBase64(buffer: any){
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i=0;i<len;i++)
        binary += String.fromCharCode(bytes[i]);
    return window.btoa(binary);
}

async function readFile(file : any) {
    return new Promise((resolve, reject) => {
      // Create file reader
      let reader = new FileReader()
  
      // Register event listeners
      reader.addEventListener("loadend", e => resolve(e.target.result))
      reader.addEventListener("error", reject)
  
      // Read file
      reader.readAsArrayBuffer(file)
    })
  }

export async function getAsByteArray(file: any) {
    return new Uint8Array(await this.readFile(file))
}

export function isPDF(fileData: any) {
  const magicNumber = '%PDF-1.'
  const buffer = new Uint8Array(fileData.slice(0, magicNumber.length))
  //@ts-ignore
  const bufferString = String.fromCharCode.apply(null,buffer)
  return bufferString === magicNumber
}

export function createAndDownloadBlobFile(
  content: Array<number>,
  filename: string,
  extension: string
) {
  const blob = new Blob([new Uint8Array(content)])
  const fileName = `${filename}.${extension}`
  const link = document.createElement('a')
  // Browsers that support HTML5 download attribute
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', fileName)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}