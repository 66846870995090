
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
/* CONSTANTS */
/* INTERFACES */
/* FUNCTIONS */
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import { showToast } from '@/utils/global-functions';
import { REMITTANCE_TYPES } from '@/modules/remittance/constants/remittance.constants';
import CodeInput from 'vue-verification-code-input';
import VuePhoneNumberInput from 'vue-phone-number-input';
import { searchFilter } from '@/utils/filter.functions';
import { countries, verifLevels } from '@/store/namespaces';
import CountryMethods from '@/store/countries/methods/countries.methods';
import VerifLevelMethods from '@/store/verificationLevels/methods/verificationLevels.methods';
import { State } from '@/modules/countries/interfaces/states.interface';
import { UpdateLevelOneInfo } from '@/modules/verificationLevels/interfaces/requests.interface';

@Component({
    components: {
        SvgIcon,
        CodeInput,
        VuePhoneNumberInput,
    },
})
export default class WelcomePopup extends Vue {
    @Prop() showModal!: boolean;
    @Prop() noClose?: boolean;
    @Prop() userData!: any;
    @Prop() xColor!: string;
    show: boolean = this.showModal ? this.showModal : false;
    inputDisabled = false;
    mainTranslations: any = {
        countrySelectorLabel: 'Código país',
        countrySelectorError: 'Elige un país',
        phoneNumberLabel: '',
        example: 'Ejemplo :',
    };
    dropdownCountries: any = [];
    stateSearch = this.userData.state_name || '';
    disableContinueButton = false;
    loading: boolean = false;

    /* MODAL */
    @Watch('showModal')
    async renderModal() {
        this.show = this.showModal;
    }

    @Watch('show')
    sendToParent(newVal: boolean) {
        this.$emit('changeModalStatus', newVal);
    }

    get mainPhoneCountryCode() {
        return this.userData.iso_code_resid_country || 'CO';
    }

    get stateCriteria() {
        return this.stateSearch.trim().toLowerCase();
    }

    get availableStateOptions() {
        const stateCriteria = this.stateCriteria;
        if (stateCriteria) {
            return searchFilter(
                this.states,
                ['state_name'],
                this.stateCriteria
            );
        }
        return this.states;
    }

    get searchDesc() {
        if (this.stateCriteria && this.availableStateOptions.length === 0) {
            return 'No se encontraron coincidencias';
        }
        return '';
    }

    get continueButton() {
        return (
            this.userData.occupation &&
            this.userData.state_name &&
            this.userData.resid_city &&
            this.userData.address
        );
    }

    async submit() {
        console.log('SUBMIT userData', this.userData);
        this.loading = true;
        await this.updateLevelOneInfo({
            email_user: this.userData.email_user,
            occupation: this.userData.occupation,
            state_name: this.userData.state_name,
            resid_city: this.userData.resid_city,
            address: this.userData.address,
        });
        showToast('Código correcto. Continúe con su remesa.', 'success');
        this.showModal = false;
        this.loading = false;
    }

    mounted() {
        console.log('this.userData', this.userData);
        this.fetchStatesByResidCountry(this.userData.id_resid_country);
        this.dropdownCountries.push(this.userData.iso_code_resid_country);
    }
    /* END MODAL */

    @countries.Action(
        CountryMethods.actions.FETCH_COUNTRY_STATES_BY_RESID_COUNTRY
    )
    fetchStatesByResidCountry!: (
        countryId: number | string
    ) => Promise<boolean>;
    @countries.Getter(CountryMethods.getters.GET_COUNTRY_STATES)
    states!: State[];
    @verifLevels.Action(VerifLevelMethods.actions.UPDATE_LEVEL_ONE_INFO)
    updateLevelOneInfo!: (data: UpdateLevelOneInfo) => Promise<boolean>;
}
